//
// Theme dark mode
// --------------------------------------------------

@if $enable-dark-mode {
   .dark-mode {
      --#{$prefix}gray-600: rgba(#{to-rgb($white)}, 0.5);
      --#{$prefix}gray-700: rgba(#{to-rgb($white)}, 0.7);
      --#{$prefix}gray-800: rgba(#{to-rgb($white)}, 0.85);
      --#{$prefix}gray-900: #{$white};
      --#{$prefix}body-bg: #{$body-dark-mode-bg};
      --#{$prefix}body-color: #{$body-dark-mode-color};
      --#{$prefix}secondary-bg: rgba(#{to-rgb($white)}, 0.04);
      --#{$prefix}heading-link-color: #{$nav-link-dark-mode-color};
      --#{$prefix}heading-link-hover-color: #{$nav-link-dark-mode-hover-color};
      --#{$prefix}nav-link-color: #{$nav-link-dark-mode-color};
      --#{$prefix}border-color: #{$border-light-color};
      --#{$prefix}code-color: #{$code-dark-mode-color};

      // Utilities

      .d-dark-mode-none {
         display: none !important;
      }
      .d-dark-mode-block {
         display: block !important;
      }
      .d-dark-mode-inline {
         display: inline !important;
      }
      .d-dark-mode-inline-block {
         display: inline-block !important;
      }
      .d-dark-mode-inline-flex {
         display: inline-flex !important;
      }
      .d-dark-mode-flex {
         display: flex !important;
      }
      .text-border {
         color: var(--#{$prefix}border-color) !important;
      }
      .text-dark:not(.badge) {
         color: $white !important;
      }
      .text-nav:not(.badge.bg-white) {
         color: $nav-link-dark-mode-color !important;
      }
      .text-body {
         color: $body-dark-mode-color !important;
      }
      .border-dark {
         border-color: var(--#{$prefix}border-color) !important;
      }
      .bg-light:not(.badge),
      .card.card-hover.bg-light {
         background-color: $dark !important;
      }
      .bg-secondary,
      .card.bg-light {
         background-color: var(--#{$prefix}secondary-bg) !important;
      }
      .bg-secondary.badge {
         color: var(--#{$prefix}body-color);
      }
      .shadow-dark-mode-none {
         box-shadow: none !important;
      }

      // Restore to defaults when inside white background
      .bg-white,
      .ignore-dark-mode {
         --#{$prefix}body-color: #{$body-color};
         --#{$prefix}nav-link-color: #{$nav-link-color};
         --#{$prefix}border-color: #{$border-color};
         h1,
         .h1,
         h2,
         .h2,
         h3,
         .h3,
         h4,
         .h4,
         h5,
         .h5,
         h6,
         .h6 {
            color: $dark;
         }
         p,
         ul,
         ol,
         span,
         dd {
            color: var(--#{$prefix}body-color);
         }
         .text-body {
            color: var(--#{$prefix}body-color) !important;
         }
         .text-muted {
            color: $gray-600 !important;
         }
         .text-dark {
            color: $dark !important;
         }
      }

      // Images / figures

      .img-thumbnail {
         background-color: $thumbnail-dark-mode-bg;
      }
      .dark-mode-img {
         display: block;
      }
      .light-mode-img {
         display: none;
      }

      // Tables

      .table {
         --#{$prefix}table-striped-bg: rgba(#{to-rgb($white)}, #{$table-striped-bg-factor});
         --#{$prefix}table-hover-bg: rgba(#{to-rgb($white)}, #{$table-hover-bg-factor});
         --#{$prefix}table-hover-color: #{$table-hover-color};
         > :not(:last-child) > :last-child > * {
            border-bottom-color: rgba($white, 0.3);
         }
      }

      // Buttons

      .btn-outline-secondary {
         --#{$prefix}btn-color: rgba(#{to-rgb($light)}, 0.85);
         --#{$prefix}btn-hover-color: #{$gray-800};
         --#{$prefix}btn-active-color: #{$gray-800};
         --#{$prefix}btn-border-color: rgba(#{to-rgb($light)}, 0.25);
         --#{$prefix}btn-hover-bg: #{$light};
         --#{$prefix}btn-active-bg: #{$light};
      }
      .btn-secondary:not(.bg-white) {
         --#{$prefix}btn-color: rgba(#{to-rgb($light)}, 0.85);
         --#{$prefix}btn-hover-color: #{$light};
         --#{$prefix}btn-active-color: #{$light};
         --#{$prefix}btn-border-color: transparent;
         --#{$prefix}btn-hover-border-color: transparent;
         --#{$prefix}btn-active-border-color: transparent;
         --#{$prefix}btn-bg: rgba(#{to-rgb($light)}, 0.06);
         --#{$prefix}btn-hover-bg: rgba(#{to-rgb($light)}, 0.12);
         --#{$prefix}btn-active-bg: rgba(#{to-rgb($light)}, 0.12);
      }
      .btn-secondary,
      .btn-outline-secondary,
      .btn-light,
      .btn-outline-light {
         --#{$prefix}btn-disabled-color: rgba(#{to-rgb($white)}, 0.5);
      }
      .btn-dark {
         --#{$prefix}btn-color: #{$gray-800} !important;
         --#{$prefix}btn-hover-color: #{$gray-800} !important;
         --#{$prefix}btn-active-color: #{$gray-800} !important;
         --#{$prefix}btn-bg: #{$gray-200};
         --#{$prefix}btn-hover-bg: #{darken($gray-200, 6%)};
         --#{$prefix}btn-active-bg: #{darken($gray-200, 6%)};
      }

      // Video button

      .btn-video {
         --#{$prefix}btn-video-bg: #{$btn-video-dark-mode-bg};
         --#{$prefix}btn-video-hover-bg: #{$btn-video-dark-mode-hover-bg};
         --#{$prefix}btn-video-color: #{$btn-video-dark-mode-color};
         --#{$prefix}btn-video-hover-color: #{$btn-video-dark-mode-hover-color};
         --#{$prefix}btn-video-shadow: #{$btn-video-dark-mode-box-shadow};
         --#{$prefix}btn-video-hover-shadow: #{$btn-video-dark-mode-hover-box-shadow};
      }

      // Scroll to top button

      .btn-scroll-top {
         --#{$prefix}btn-scroll-top-color: #{$btn-scroll-top-dark-mode-color};
         --#{$prefix}btn-scroll-top-hover-color: #{$btn-scroll-top-dark-mode-hover-color};
         --#{$prefix}btn-scroll-top-bg: #{$btn-scroll-top-dark-mode-bg};
         --#{$prefix}btn-scroll-top-hover-bg: #{$btn-scroll-top-dark-mode-hover-bg};
      }

      // Forms

      .form-label,
      .col-form-label {
         color: $form-label-dark-mode-color;
      }
      .form-text {
         color: $form-text-dark-mode-color;
      }
      .form-control,
      .form-select,
      .typeselect-input-field {
         border-color: $input-dark-mode-border-color;
         &.normal {
            border-color: $input-dark-mode-border-color;
         }
         background-color: $input-dark-mode-bg;
         color: $input-dark-mode-color;
         &:focus {
            border-color: $input-dark-mode-focus-border-color;
            box-shadow: none;
         }
         &:disabled,
         &[readonly] {
            border-color: $input-dark-mode-disabled-border-color;
            background-color: $input-dark-mode-disabled-bg;
         }
      }
      .form-control {
         &::placeholder {
            color: $input-dark-mode-placeholder-color;
         }
         &::file-selector-button {
            @include gradient-bg($form-file-dark-mode-button-bg);
            color: $form-file-dark-mode-button-color;
         }
         &:hover:not(:disabled):not([readonly])::file-selector-button {
            @include gradient-bg($form-file-dark-mode-button-bg);
            color: $form-file-dark-mode-button-color;
         }
         &::-webkit-file-upload-button {
            @include gradient-bg($form-file-dark-mode-button-bg);
            color: $form-file-dark-mode-button-color;
         }
         &:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
            @include gradient-bg($form-file-dark-mode-button-bg);
            color: $form-file-dark-mode-button-color;
         }
         &:focus:not(:disabled):not([readonly])::file-selector-button {
            @include gradient-bg($form-file-button-hover-bg);
            color: $form-file-button-color;
         }
         &:focus:not(:disabled):not([readonly])::-webkit-file-upload-button {
            @include gradient-bg($form-file-button-hover-bg);
            color: $form-file-button-color;
         }
      }
      .form-select {
         background-image: escape-svg($form-select-dark-mode-indicator);
      }
      .form-floating .form-control,
      .form-floating .form-select {
         &::placeholder {
            color: transparent;
         }
         &:focus ~ label {
            color: $form-label-dark-mode-focus-color;
         }
      }

      .form-select:not([multiple]) {
         option {
            color: $gray-800;
         }
         option[disabled] {
            color: $gray-600;
         }
      }
      @-moz-document url-prefix() {
         .form-select:not([multiple]) {
            option {
               color: $gray-800;
            }
            option[disabled] {
               color: $gray-600;
            }
         }
      }

      .form-range {
         &::-webkit-slider-runnable-track {
            background-color: $form-range-dark-mode-track-bg;
         }
         &::-moz-range-track {
            background-color: $form-range-dark-mode-track-bg;
         }
      }

      .form-check-label {
         color: $body-dark-mode-color;
      }
      .form-check-input:not(:checked):not(.is-invalid):not(:invalid) {
         border-color: $form-check-input-dark-mode-border-color;
      }
      .form-check-input:not(:checked) {
         background-color: $form-check-input-dark-mode-bg;
      }
      .form-switch {
         .form-check-input {
            background-color: $form-switch-dark-mode-bg;
            &:checked {
               background-color: $form-switch-dark-mode-checked-bg;
            }
         }
         &.mode-switch,
         &.price-switch {
            .form-check-label:first-of-type {
               color: $white;
            }
            .form-check-input {
               background-color: $form-switch-checked-bg;
               &:checked {
                  & ~ .form-check-label:first-of-type {
                     color: rgba($white, 0.6);
                  }
                  & ~ .form-check-label:last-of-type {
                     color: $white;
                  }
               }
            }
         }

         &.form-switch-success .form-check-input:checked {
            background-color: $success;
         }
      }

      .form-control ~ .password-toggle-btn .password-toggle-indicator {
         color: rgba($white, 0.4);
         &:hover {
            color: rgba($white, 0.85);
         }
      }

      .input-group-text {
         border-color: $input-group-dark-mode-addon-border-color;
         background-color: $input-group-dark-mode-addon-bg;
         color: $input-group-dark-mode-addon-color;
      }

      .range-slider {
         --#{$prefix}range-slider-bg: #{$range-slider-dark-mode-bg};
         --#{$prefix}range-slider-handle-bg: #{$gray-200};
         --#{$prefix}range-slider-pips-border-color: rgba(#{to-rgb($white)}, 0.2);
      }

      .input-group {
         .input-group-text {
            &:first-of-type:not(:last-child) {
               border-right-color: transparent;
            }
            &:last-of-type:not(:first-child) {
               border-left-color: transparent;
            }
            &:first-child + .input-group-text {
               border-left-color: $input-dark-mode-border-color;
            }
         }
      }

      .was-validated :valid:not(:required):not(.btn),
      .is-valid:not(:required):not(.btn) {
         border-color: $input-dark-mode-border-color !important;
      }

      .credit-card-icon {
         background-position: 0 -26px;
      }

      // Navs

      .nav {
         --#{$prefix}nav-link-color: #{$nav-link-dark-mode-color};
         --#{$prefix}nav-link-hover-color: #{$nav-link-dark-mode-hover-color};
         --#{$prefix}nav-link-active-color: #{$nav-link-dark-mode-active-color};
         --#{$prefix}nav-link-disabled-color: #{$nav-link-dark-mode-disabled-color};
      }

      .nav-tabs {
         --#{$prefix}nav-tabs-link-bg: #{$nav-tabs-dark-mode-link-bg};
         --#{$prefix}nav-tabs-link-hover-bg: #{$nav-tabs-dark-mode-link-hover-bg};
         --#{$prefix}nav-tabs-link-active-bg: #{$nav-tabs-dark-mode-link-active-bg};
         --#{$prefix}nav-tabs-link-disabled-bg: #{$nav-tabs-dark-mode-link-disabled-bg};
         --#{$prefix}nav-tabs-link-color: #{$nav-tabs-dark-mode-link-color};
         --#{$prefix}nav-tabs-link-hover-color: #{$nav-tabs-dark-mode-link-hover-color};
         --#{$prefix}nav-tabs-link-active-color: #{$nav-tabs-dark-mode-link-active-color};
         --#{$prefix}nav-tabs-link-disabled-color: #{$nav-tabs-dark-mode-link-disabled-color};
      }

      .nav-tabs-alt {
         --#{$prefix}nav-tabs-alt-border-color: #{$border-light-color};
      }

      .side-nav {
         --#{$prefix}side-nav-border-color: #{$side-nav-dark-mode-border-color};
      }

      // Navbar

      .navbar {
         --#{$prefix}navbar-stuck-bg: #{$dark};
         --#{$prefix}navbar-brand-color: #{$navbar-dark-brand-color};
         --#{$prefix}navbar-brand-hover-color: #{$navbar-dark-brand-hover-color};
         --#{$prefix}navbar-toggler-color: #{$navbar-dark-toggler-bar-color};
         --#{$prefix}navbar-color: #{$navbar-dark-color};
         --#{$prefix}navbar-hover-color: #{$navbar-dark-hover-color};
         --#{$prefix}navbar-disabled-color: #{$navbar-dark-disabled-color};
         --#{$prefix}navbar-active-color: #{$navbar-dark-active-color};
      }
      .navbar-stuck {
         box-shadow: none;
      }

      // Dropdowns

      .dropdown-menu {
         @extend .dropdown-menu-dark;
      }

      // Placeholders

      .placeholder {
         opacity: $placeholder-dark-mode-opacity-max;
      }
      @keyframes placeholder-glow {
         50% {
            opacity: $placeholder-dark-mode-opacity-min;
         }
      }
      .placeholder-wave {
         mask-image: linear-gradient(
            130deg,
            $black 55%,
            rgba(0, 0, 0, (1 - $placeholder-dark-mode-opacity-min)) 75%,
            $black 95%
         );
      }

      // Cards

      .card {
         --#{$prefix}card-border-color: #{$card-dark-mode-border-color};
         --#{$prefix}card-bg: #{$card-dark-mode-bg};
         --#{$prefix}card-color: #{$card-dark-mode-color};
         &.shadow-sm,
         &.shadow,
         &.shadow-lg,
         &.border-0 {
            background-color: var(--#{$prefix}secondary-bg);
         }
      }
      .card-hover {
         &:hover {
            border-color: rgba($white, 0.3);
            background-color: var(--#{$prefix}secondary-bg);
         }
         &.border-0:not(.bg-transparent):not(.bg-light) {
            border: var(--#{$prefix}border-width) solid transparent !important;
            &:hover {
               border-color: var(--#{$prefix}card-border-color) !important;
            }
         }
         &.bg-light:hover {
            background-color: var(--#{$prefix}secondary-bg) !important;
         }
      }

      // Accordion

      .accordion {
         --#{$prefix}accordion-border-color: #{$accordion-dark-mode-border-color};
         --#{$prefix}accordion-bg: #{$accordion-dark-mode-bg};
         --#{$prefix}accordion-btn-bg: #{$accordion-dark-mode-bg};
         --#{$prefix}accordion-active-bg: #{$accordion-dark-mode-bg};
         --#{$prefix}accordion-btn-color: #{$accordion-dark-mode-button-color};
         --#{$prefix}accordion-active-color: #{$accordion-dark-mode-button-color};
         --#{$prefix}accordion-btn-icon: #{escape-svg($accordion-dark-mode-button-icon)};
         --#{$prefix}accordion-btn-icon-box-bg: #{$accordion-dark-mode-icon-box-bg};
         --#{$prefix}accordion-btn-icon-box-active-bg: #{$accordion-dark-mode-icon-box-active-bg};
      }
      .accordion-item.border-0 {
         background-color: var(--#{$prefix}secondary-bg);
      }
      .accordion-button.shadow-none {
         background-color: transparent !important;
      }

      // Popovers

      .popover {
         --#{$prefix}popover-bg: #{$popover-dark-mode-bg};
         --#{$prefix}popover-border-color: var(--#{$prefix}border-color);
         --#{$prefix}popover-body-color: var(--#{$prefix}body-color);
         --#{$prefix}popover-header-color: var(--#{$prefix}heading-color);
         --#{$prefix}popover-arrow-border: rgba(#{to-rgb($white)}, 0.2);
      }

      // Modal

      .modal {
         --#{$prefix}modal-bg: #{$modal-dark-mode-content-bg};
         --#{$prefix}modal-border-color: #{$modal-dark-mode-content-border-color};
         --#{$prefix}modal-border-width: #{$modal-dark-mode-content-border-width};
         --#{$prefix}modal-header-border-color: #{$modal-dark-mode-content-border-color};
         --#{$prefix}modal-footer-border-color: #{$modal-dark-mode-content-border-color};
      }

      // Alerts

      @each $color, $value in $theme-colors {
         .alert-#{$color} {
            --#{$prefix}alert-bg: rgba(#{to-rgb($value)}, 0.05);
            --#{$prefix}alert-border-color: rgba(#{to-rgb($value)}, 0.25);
         }
      }
      .alert-secondary,
      .alert-light,
      .alert-dark {
         --#{$prefix}alert-border-color: var(--#{$prefix}border-color);
      }
      .alert-secondary,
      .alert-light {
         --#{$prefix}alert-bg: var(--#{$prefix}secondary-bg);
         --#{$prefix}alert-color: var(--#{$prefix}body-color);
         --#{$prefix}alert-link-color: #{$white};
      }

      // Progress bars

      .progress {
         --#{$prefix}progress-bg: #{$progress-dark-mode-bg};
      }
      .progress-bar.bg-dark {
         --#{$prefix}progress-bar-bg: #{$light};
         --#{$prefix}progress-bar-color: #{$gray-900};
         background-color: var(--#{$prefix}progress-bar-bg) !important;
         &.progress-bar-striped {
            background-image: linear-gradient(
               45deg,
               rgba($dark, 0.15) 25%,
               transparent 25%,
               transparent 50%,
               rgba($dark, 0.15) 50%,
               rgba($dark, 0.15) 75%,
               transparent 75%,
               transparent
            );
         }
      }

      // List group

      .list-group {
         --#{$prefix}list-group-color: #{$list-group-dark-mode-color};
         --#{$prefix}list-group-border-color: #{$list-group-dark-mode-border-color};
         --#{$prefix}list-group-active-border-color: #{$list-group-active-border-color};
         --#{$prefix}list-group-disabled-color: #{$list-group-dark-mode-disabled-color};
         --#{$prefix}list-group-action-color: #{$list-group-dark-mode-action-color};
         --#{$prefix}list-group-action-hover-color: #{$list-group-action-hover-color};
      }

      // Toasts

      .toast {
         --#{$prefix}toast-bg: #{$toast-dark-mode-bg};
         --#{$prefix}toast-border-color: var(--#{$prefix}border-color);

         background: var(--#{$prefix}toast-bg);
      }

      // Breadcrumb

      .breadcrumb-item {
         --#{$prefix}breadcrumb-item-active-color: #{$breadcrumb-dark-mode-active-color};
      }

      // Pagination

      .pagination {
         --#{$prefix}pagination-color: #{$pagination-dark-mode-color};
         --#{$prefix}pagination-active-color: #{$pagination-dark-mode-active-color};
         --#{$prefix}pagination-hover-color: #{$pagination-dark-mode-hover-color};
         --#{$prefix}pagination-hover-bg: #{$pagination-dark-mode-hover-bg};
         --#{$prefix}pagination-focus-bg: #{$pagination-dark-mode-hover-bg};
         --#{$prefix}pagination-disabled-color: #{$pagination-dark-mode-disabled-color};
      }

      // Close

      .btn-close {
         filter: $btn-close-white-filter;
      }

      // Offcanvas

      .offcanvas,
      .offcanvas-sm,
      .offcanvas-md,
      .offcanvas-lg,
      .offcanvas-xl,
      .offcanvas-xxl {
         --#{$prefix}offcanvas-bg: #{$offcanvas-dark-mode-bg-color};
         &.bg-secondary {
            background-color: #14171f !important;
         }
      }

      // Code

      :not(pre) > code[class*='language-'],
      pre[class*='language-'],
      pre {
         --#{$prefix}pre-bg: #{$pre-dark-mode-bg};
         --#{$prefix}pre-line-numbers-border-color: #{$pre-dark-mode-line-numbers-border-color};
         --#{$prefix}pre-line-numbers-color: #{$pre-dark-mode-line-numbers-color};
         --#{$prefix}pre-tag-color: #5960f3;
         --#{$prefix}pre-comment-color: rgba(#{to-rgb($white)}, 0.5);
         --#{$prefix}pre-attr-name-color: #4da2f7;
         --#{$prefix}pre-attr-value-color: #f33185;
         --#{$prefix}pre-class-name-color: #4ddcda;

         box-shadow: none;
      }
      code[class*='language-'],
      pre[class*='language-'],
      pre > code,
      pre {
         --#{$prefix}pre-color: #{$pre-dark-mode-color};
      }
      kbd {
         background-color: $kbd-dark-mode-bg;
         color: $kbd-dark-mode-color;
      }

      // Carousel

      .btn-prev,
      .btn-next {
         --#{$prefix}carousel-nav-btn-bg: #{$carousel-dark-mode-nav-btn-bg};
         --#{$prefix}carousel-nav-btn-color: #{$carousel-dark-mode-nav-btn-color};
         --#{$prefix}carousel-nav-btn-box-shadow: none;
         --#{$prefix}carousel-nav-btn-disabled-color: rgba(
            #{to-rgb($carousel-dark-mode-nav-btn-color)},
            0.5
         );
      }
      .swiper-pagination {
         --#{$prefix}carousel-pagination-bullet-bg: #{$carousel-dark-mode-bullet-bg};
         --#{$prefix}carousel-pagination-bullet-active-bg: #{$carousel-dark-mode-bullet-active-bg};
         --#{$prefix}carousel-pagination-progressbar-bg: #{$carousel-dark-mode-progressbar-bg};
      }
      .swiper-scrollbar {
         --#{$prefix}carousel-scrollbar-bg: #{$carousel-dark-mode-scrollbar-bg};
         --#{$prefix}carousel-scrollbar-drag-bg: #{$carousel-dark-mode-scrollbar-drag-bg};
      }
      .offcanvas,
      .offcanvas-sm,
      .offcanvas-md,
      .offcanvas-lg,
      .offcanvas-xl,
      .offcanvas-xl {
         .swiper-scrollbar-drag {
            opacity: 0.55;
         }
      }

      // Steps

      .steps {
         --#{$prefix}steps-number-bg: #{$steps-dark-mode-number-bg};
         --#{$prefix}steps-number-inner-bg: #{$steps-dark-mode-number-inner-bg};
      }

      // Audio player

      .ap-seek-slider::-webkit-slider-runnable-track {
         background: linear-gradient(
            to right,
            rgba($white, 0.55) var(--buffered-width),
            rgba($white, 0.3) var(--buffered-width)
         );
      }
   }
}
