@use 'sass:math';

body {
   font-size: 12px;
}

.dark-mode {
   background-color: #0e1927;
}
.page-loading {
   position: fixed;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   width: 100%;
   height: 100%;
   -webkit-transition: all 0.4s 0.2s ease-in-out;
   transition: all 0.4s 0.2s ease-in-out;
   background-color: #fff;
   opacity: 0;
   visibility: hidden;
   z-index: 9999;
}

.dark-mode .page-loading {
   background-color: #0e1927;
}

.page-loading.active {
   opacity: 1;
   visibility: visible;
}

.page-loading-inner {
   position: absolute;
   top: 50%;
   left: 0;
   width: 100%;
   text-align: center;
   -webkit-transform: translateY(-50%);
   transform: translateY(-50%);
   -webkit-transition: opacity 0.2s ease-in-out;
   transition: opacity 0.2s ease-in-out;
   opacity: 0;
}

.page-loading.active > .page-loading-inner {
   opacity: 1;
}

.page-loading-inner > span {
   display: block;
   font-size: 1rem;
   font-weight: normal;
   color: #9397ad;
}

.dark-mode .page-loading-inner > span {
   color: #fff;
   opacity: 0.6;
}

.page-spinner {
   display: inline-block;
   width: 2.75rem;
   height: 2.75rem;
   margin-bottom: 0.75rem;
   vertical-align: text-bottom;
   border: 0.15em solid #b4b7c9;
   border-right-color: transparent;
   border-radius: 50%;
   -webkit-animation: spinner 0.75s linear infinite;
   animation: spinner 0.75s linear infinite;
}

.dark-mode .page-spinner {
   border-color: rgba(255, 255, 255, 0.4);
   border-right-color: transparent;
}

@-webkit-keyframes spinner {
   100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
   }
}

@keyframes spinner {
   100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
   }
}

.fs-8p {
   font-size: 8px !important;
}

.fs-9p {
   font-size: 9px !important;
}

.fs-10p {
   font-size: 10px !important;
}

.fs-11p {
   font-size: 11px !important;
}

.fs-12p {
   font-size: 12px !important;
}

.fs-13p {
   font-size: 13px !important;
}

.fs-14p {
   font-size: 14px !important;
}

.fs-18p {
   font-size: 18px !important;
}

.pt-40p {
   padding-top: 40px;
}

.pt-50p {
   padding-top: 50px;
}

.mt-50p {
   margin-top: 50px;
}

.mt-54p {
   margin-top: 54px;
}

.mt-60p {
   margin-top: 60px;
}

.mt-70p {
   margin-top: 70px;
}

.mt-80p {
   margin-top: 80px;
}

.mt-90p {
   margin-top: 90px;
}

.pt-header {
   padding-top: 40px;
}

.badge-border {
   border: 1px solid;
}

.queryFont {
   font-family: 'JetBrains Mono';
   font-size: 12px;
}

/*
* CodeMirror customization in react this is .cm not .CodeMirror
 */
.code-container {
   //background-color: rgba(36,50,72,1);
   //background-color: #0E1927 !important;
}

.cm-tooltip-cursor.cm-tooltip.cm-tooltip-above {
   padding-left: 0.25rem !important;
   padding-right: 0.25rem !important;
}

.card .queryRun .cm-scroller {
   align-items: stretch !important;
}

.cm-foldGutter {
   order: -1;
}

.cm-foldGutter {
   margin-top: -2px;
}

.queryFontSmall .cm-editor {
   font-family: 'JetBrains Mono';
   font-size: 11px !important;
   color: #0e1927;
}

.dark-mode .code-container {
   background-color: #0e1927;
}

.cm-editor {
   font-family: 'JetBrains Mono', monospace;
   font-weight: 500;
   font-size: 12px;
   //color: #0E1927 !important;
   height: 100%;
   padding: 0 !important;
}

.dark-mode .cm-editor {
   color: #e2e5f1 !important;
}

.dark-mode .editable .cm-editor {
   //background-color: black;
}

.cm-focused {
   outline: none !important;
}

.cm-cursor {
   border-left: 1px solid #0e1927 !important;
}

.dark-mode .cm-cursor {
   border-left: 1px solid #e2e5f1 !important;
}

.cm-hints {
   border: 1px solid #b4b7c9 !important;
}

.dark-mode .cm-hints {
   background: #0e1927 !important;
}

.dark-mode .dm-hint {
   color: #e2e5f1 !important;
}

li.cm-hint-active {
   background: #4c82f7 !important;
   color: #fff;
}

.cm-tooltip {
   //background-color: rgba(36, 50, 72, 0.9) !important;
   //border: 1px solid rgba(36, 50, 72, 0.9) !important;
   background-color: rgba(36, 50, 72, 1);
   border-radius: 4px;
   padding: 4px;
}

.dark-mode .cm-tooltip {
   //border: 1px solid #0E1927 !important;
   background-color: rgba(36, 50, 72, 1);
   border-radius: 4px;
   padding: 4px;
}

.cm-tooltip-autocomplete {
   background-color: transparent;
   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
   padding: 2px 2px;
   margin-top: 2px;
   margin-bottom: 2px;
   font-size: 13px;
   max-height: 200px;
   overflow-y: auto;
}

.cm-tooltip-autocomplete ul li[aria-selected] {
   background: #0e1927 !important;
   color: #fff;
}

.cm-tooltip-autocomplete .CodeMirror-hint {
   padding: 0;
   cursor: pointer;
}

.cm-tooltip-autocomplete .CodeMirror-hint.CodeMirror-hint-active {
   background-color: #e2e5f1;
}

.cm-tooltip-autocomplete .CodeMirror-hint.CodeMirror-hint-active,
.cm-tooltip-autocomplete .CodeMirror-hint:hover {
   color: #0e1927;
}

.cm-tooltip.cm-tooltip-autocomplete > ul {
   font-family: 'JetBrains Mono', monospace;
}

.dark-mode .cm-tooltip-autocomplete .CodeMirror-hint.CodeMirror-hint-active {
   background-color: #e2e5f1;
}

.dark-mode .cm-tooltip-autocomplete .CodeMirror-hint.CodeMirror-hint-active,
.cm-tooltip-autocomplete .CodeMirror-hint:hover {
   color: #0e1927;
}

/* this is to hide the ugly key that they put in front of autocomplete items */
.cm-completionIcon-keyword:after {
   content: '' !important;
}

.cm-completionIcon {
   width: 0 !important;
   display: none !important;
   padding: 0;
}

/* this is to hide the underline on matched word */
.cm-completionMatchedText {
   //text-decoration: none !important;
}

.cm-line {
   box-shadow: none;
   padding-left: 14px !important;
}

.cm-linenumber {
   color: #9397ad !important;
   background-color: transparent !important;
   min-width: 30px;
   padding-left: 2px !important;
   padding-right: 4px !important;
   width: 30px !important;
}

.dark-mode .cm-linenumber {
   color: #565973 !important;
   background-color: transparent !important;
}

.cm-gutters {
   display: flex;
   height: unset !important;
   background-color: transparent !important;
   padding: 8px 6px 8px 8px;
   border-right: 1px solid rgba(36, 50, 72, 0.05) !important;
}

.dark-mode .cm-gutters {
   background-color: transparent !important;
   //border-right: none !important;
   border-right: 1px solid rgba(36, 50, 72, 0.5) !important;
}

.starting-block .cm-gutters {
   display: none !important;
}

.cm-gutter.cm-linenumbers {
   width: 30px;
}

.cm-content {
   margin-top: 7px !important;
   margin-bottom: 7px !important;
}

.cm-activeLineGutter {
   background-color: transparent !important;
}

.cm-focused .cm-matchhighlight {
   background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAFklEQVQI12NgYGBgkKzc8x9CMDAwAAAmhwSbidEoSQAAAABJRU5ErkJggg==);
   background-position: bottom;
   background-repeat: repeat-x;
}

.cm-matchhighlight {
   //background-color: rgba(255, 186, 8, 0.12) !important;
}

.cm-selection-highlight-scrollbar {
   //background-color: rgba(255, 186, 8, 0.12) !important;
}

.dark-mode .cm-keyword {
   color: #9397ad !important;
}

.dark-mode .cm-operator {
   color: #fd7e14 !important;
}

.dark-mode .cm-string {
   color: #198754 !important;
}

.dark-mode .cm-number {
   color: #4c82f7 !important;
}

.dark-mode .cm-variable-2 {
   color: #e2e5f1 !important;
}

.cm-keyword {
   color: #565973 !important;
}

.cm-operator {
   color: #fd7e14 !important;
}

.cm-string {
   color: #198754 !important;
}

.cm-number {
   color: #4c82f7 !important;
}

.cm-variable-2 {
   color: #0e1927 !important;
}

.cm-activeLine {
   background-color: transparent !important;
}

/*operator*/
.cm-line {
   line-height: 18px;
}

.cm-selectionMatch,
.cm-selectionBackground {
   background-color: rgba(36, 50, 72, 0.3) !important;
}

.dark-mode .cm-selectionBackground,
.dark-mode .cm-selectionMatch {
   background-color: rgba(36, 50, 72, 1) !important;
}

.code-viewer {
   white-space: pre-wrap;
   display: block;
   padding: 0 !important;
   color: #0e1927;
}

.code-viewer.collapsed-code-viewer .query-line {
   display: block;
   max-width: 100%;
   text-wrap: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}
/*
* End CodeMirror Customization
 */

.btn {
   font-size: 11px;
   padding-top: 8px;
   padding-bottom: 8px;
}

.btn-xs {
   padding-top: 6px;
   padding-bottom: 6px;
   padding-left: 8px;
   padding-right: 8px;
   border-radius: 4px;
}

.btn-xs-link {
   padding: 0;
   font-size: 11px;
   border: none;
   background-color: transparent;
   opacity: 0.8;
}

.btn-xs-link:hover {
   border: none;
   background-color: transparent;
   color: #4a4eef !important;
}

.btn-xxs {
   padding-top: 2px;
   padding-bottom: 2px;
   padding-left: 6px;
   padding-right: 6px;
   font-size: 10px;
   border-radius: 4px;
   font-weight: 500;
}

.btn-xxs-link {
   padding: 0;
   font-size: 10px;
   border: none;
   background-color: transparent;
   opacity: 0.8;
}

.btn-xxs-link:hover {
   border: none;
   background-color: transparent;
   color: #4a4eef !important;
}

.custom-button-link {
   background: none;
   border: none;
   padding: 0;
   font: inherit;
   cursor: pointer;
   outline: inherit;
   font-weight: 600;
   text-decoration: none;
   padding-left: 0.25rem !important;
   padding-right: 0 !important;
   margin-left: 0.25rem !important;
   margin-right: 0 !important;
   vertical-align: middle !important;
   color: #4c82f7;
}

.custom-button-link:hover {
   color: #4c82f7;
}

.custom-button-link-secondary {
   font-weight: 600;
   text-decoration: none;
   padding-left: 0.25rem !important;
   padding-right: 0 !important;
   margin-left: 0.25rem !important;
   margin-right: 0 !important;
   vertical-align: middle !important;
   opacity: 0.5 !important;
   color: #4c82f7;
}

.custom-button-link-secondary:hover {
   opacity: 1 !important;
   color: #4c82f7;
}

.custom-button-link-no-spacing {
   font-weight: 600;
   text-decoration: none;
   padding-left: 0 !important;
   padding-right: 0 !important;
   margin-left: 0 !important;
   margin-right: 0 !important;
   vertical-align: bottom !important;
   color: #4c82f7;
}

.custom-button-link-no-spacing:hover {
   color: #4c82f7;
}

.custom-button-link-no-spacing-middle {
   font-weight: 600;
   text-decoration: none;
   padding-left: 0 !important;
   padding-right: 0 !important;
   margin-left: 0 !important;
   margin-right: 0 !important;
   vertical-align: middle !important;
}

.custom-button-link-no-spacing-middle:hover {
   color: #4c82f7;
}

.custom-button-link-secondary-no-spacing {
   font-weight: 600;
   text-decoration: none;
   padding-left: 0 !important;
   padding-right: 0 !important;
   margin-left: 0 !important;
   margin-right: 0 !important;
   //vertical-align: bottom !important;
   opacity: 0.5 !important;
   color: #4c82f7;
}

.custom-button-link-secondary-no-spacing:hover {
   opacity: 1 !important;
   color: #4c82f7;
}

.container-fluid {
   width: 99% !important;
}

.max-width-98 {
   max-width: 98%;
}

.max-width-90 {
   max-width: 90%;
}

/*
*
* If we ever want to have the nav tabs not wrap and have a scroll to see more of them.
*
*/
.tabSpecialNoWrap {
   overflow-x: auto;
   overflow-y: hidden;
   flex-wrap: nowrap;
}

.tabSpecialNoWrap .nav-tabs .nav-link {
   white-space: nowrap;
}

/*
*
* end
*
*/

.override-active-pointer {
   pointer-events: auto !important;
   cursor: pointer !important;
}

.active-background-on-hover {
   max-width: unset;
}

.active-background-on-hover:hover {
   //background-color: #e2e5f1;
   border-radius: 100%;
   color: #4c82f7;
   max-width: unset;
}

.dark-mode .active-background-on-hover:hover {
   //background-color: #e2e5f1;
   //border-radius: 100%;
   color: #fff;
   max-width: unset;
}

.secondaryTabs .nav-item {
   margin-right: 8px;
}

.secondaryTabs.nav-tabs .nav-link {
   /*border-radius: 0.5rem;
  border: 1px solid #e2e5f1;*/
   //opacity: 0.6;
   padding: 6px 8px 6px 8px;
}

.secondaryTabs.nav-tabs .nav-link:hover {
   //border: 1px solid #4c82f7;
   //background-color: #cfd8f6 !important;
   background-color: #b4b7c9;
}

.secondaryTabs.nav-tabs .nav-link.active,
.secondaryTabs.nav-tabs .nav-item.show .nav-link {
   //background-color: rgba(99, 102, 241, 0.12) !important;
   background-color: rgba(36, 50, 72, 0.09) !important;
   //opacity: 0.85;
   //background-color: transparent !important;
   //border: 1px solid #4c82f7 !important;
   color: #33354d !important;
}

.dark-mode .secondaryTabs.nav-tabs .nav-link.active,
.dark-mode .secondaryTabs.nav-tabs .nav-item.show .nav-link {
   background-color: rgba(36, 50, 72, 0.3) !important;
   color: white !important;
}

.nav-link.unread {
   color: #33354d !important;
}
.dark-mode .nav-link.unread {
   color: white !important;
}

.dark-mode .secondaryTabs.nav-tabs .nav-link:hover {
   background-color: rgba(36, 50, 72, 0.3) !important;
   color: white !important;
}

.nav-tabs-alt {
   margin: 0;
   border: 0;
   padding: 0;
   font-size: 13px;
}

.nav-tabs-alt .nav-link {
   padding: 8px 0 1px;
   border-bottom-width: 1px;
   border-bottom-color: transparent;
   margin-right: 16px;
}

.nav-tabs-alt .nav-link:hover {
   color: #0e1927;
}

.nav-tabs-alt .nav-link.active {
   border-bottom-color: #0e1927;
   background-color: transparent !important;
   color: #0e1927;
   /*#3A68CB*/
}

.dark-mode .nav-tabs-alt .nav-link:hover {
   color: #fff;
}

.dark-mode .nav-tabs-alt .nav-link.active {
   border-bottom-color: #fff;
   background-color: transparent !important;
   color: #fff;
   /*#3A68CB*/
}

.starting-block-panel {
   background-color: rgba(36, 50, 72, 0.05);
}

.dark-mode .starting-block-panel {
   background-color: #0e1927;
}

.starting-block {
   .nav-tabs-alt {
      font-size: 10px;
      padding-right: 16px;
      padding-left: 16px;
   }

   .nav-tabs-alt .nav-link {
      padding: 4px 0 0 0;
      border-bottom-width: 1px;
      border-bottom-color: transparent;
      margin-right: 4px;
   }

   .nav-tabs-alt .nav-link.active {
      border-bottom-color: #0e1927;
      /*#3A68CB*/
   }
}

.dark-mode .starting-block {
   .nav-tabs-alt .nav-link.active {
      border-bottom-color: #fff;
      /*#3A68CB*/
   }
}

.solid-line {
   display: flex;
   flex-direction: row;
}
.solid-line:after {
   content: '';
   flex: 1 1;
   border-bottom: 1px solid #e2e5f1;
   margin: auto;
}

.dark-mode .solid-line:after {
   border-bottom: 1px solid rgba(255, 255, 255, 0.14);
}

.hero-image-border {
   border: 1px solid #d4d7e5;
}

.dark-mode .hero-image-border {
   border: 1px solid #ffffff;
}

.dark-mode .hero-image-border {
   border: 1px solid rgba(255, 255, 255, 0.14);
}

.hero-image-signin-changer {
   content: url('/assets/img/sql-landing-page-light.png');
}

.dark-mode .hero-image-signin-changer {
   content: url('/assets/img/sql-landing-page-dark.png');
}

/* Datatables customization */

.dataTables_wrapper .dataTables_paginate .paginate_button {
   padding: 4px 8px 4px 8px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
   border: 0;
   background: #3a68cb;
   color: #fff !important;
}

.dataTables_info {
   font-size: 10px;
}

.dataTables_paginate {
   font-size: 10px;
}

.dataTables_length label {
   font-size: 11px;
}

.dataTables_length select {
   font-size: 11px;
}

.dataTables_filter label {
   font-size: 11px;
}

.dt-buttons {
   margin-left: 40px;
}
button.dt-button,
div.dt-button,
a.dt-button,
input.dt-button {
   position: relative;
   display: inline-block;
   box-sizing: border-box;
   margin-left: 0.167em;
   margin-right: 0.167em;
   margin-bottom: 0.333em;
   padding: 0.5em 1em;
   border: none;
   border-radius: 0.5rem;
   cursor: pointer;
   font-size: 10px;
   line-height: 12px;
   color: #4c82f7;
   font-weight: 600;
   white-space: nowrap;
   overflow: hidden;
   background-color: rgba(0, 0, 0, 0.1);
   background: transparent;
   filter: none;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   text-decoration: none;
   outline: none;
   text-overflow: ellipsis;
}

button.dt-button:hover:not(.disabled),
div.dt-button:hover:not(.disabled),
a.dt-button:hover:not(.disabled),
input.dt-button:hover:not(.disabled) {
   border: none;
   background-color: transparent;
   background: transparent;
   filter: none;
}

/* this css moves the datatables column search to the top */
.dataTables_wrapper tfoot {
   display: table-header-group;
}

.dataTables_wrapper tfoot input {
   border: 1px solid #d4d7e5;
   border-radius: 3px;
   border-width: 1px;
   border-style: solid;
}

.dark-mode .dataTables_wrapper tfoot input {
   border-color: rgba(255, 255, 255, 0.18);
   background-color: rgba(255, 255, 255, 0.02);
   color: rgba(255, 255, 255, 0.7);
   border-radius: 3px;
   border-width: 1px;
   border-style: solid;
}

.dark-mode .dataTables_length select {
   color: rgba(255, 255, 255, 0.7) !important;
}

.dark-mode .dataTables_filter input {
   color: rgba(255, 255, 255, 0.7) !important;
}

/* the following two lines make search go to the top */
.dataTables_wrapper thead {
   display: table-row-group;
}

.runql-datatables-wrapper {
   margin-bottom: 36px;
}

/*
* Data tables customization end
*/

.bgAlert {
   background-color: #e9e655 !important;
}

.bgFadedDark {
   background-color: #d4d7e5 !important;
}

.dark-mode .bgFadedDark {
   background-color: #565973 !important;
}

.badge.queryDataSourceTable {
   border: 1px solid #e2e5f1;
   color: #9397ad !important;
   border-radius: 6px;
}

.dark-mode .badge.queryDataSourceTable {
   border: 1px solid #33354d;
   color: #9397ad !important;
}

.badge.queryDatabaseSource {
   border: 1px solid #e2e5f1;
   color: #9397ad !important;
   font-weight: 500;
   border-radius: 6px;
}

.dark-mode .badge.queryDatabaseSource {
   border: 1px solid #33354d;
   color: #9397ad !important;
}

// hides dropdown arrow that shows after a dropdown toggle component
.dropdown-toggle:not(.show-arrow)::after {
   display: none;
}

.tooltip-inner {
   max-width: 1000px !important;
}

.dark-mode .tooltip-inner {
   background-color: #33354d;
}

.dark-mode .dataListBackground {
   background-color: #0e1927;
}

html {
   scroll-padding-top: 90px;
}

.ps-24p {
   padding-left: 24px;
}

.ps-36p {
   padding-left: 36px;
}

.ps-18p {
   padding-left: 18px;
}

.ps-8p {
   padding-left: 8px;
}

/*
*   AG Grid customization
*/

.ag-root-wrapper {
   border-radius: 0 !important;
   box-shadow: none !important;
   font-family: Inter, sans-serif;
   /* This ends up just being for the header and footer */
   font-size: 11px !important;
   border-top: 1px solid rgba(36, 50, 72, 0.15) !important;
   border-bottom: 1px solid rgba(36, 50, 72, 0.15) !important;
   border-left: none !important;
   border-right: none !important;
}

.dark-mode .ag-root-wrapper {
   border-top: 1px solid rgba(36, 50, 72, 0.9) !important;
   border-bottom: 1px solid rgba(36, 50, 72, 0.9) !important;
   border-left: none !important;
   border-right: none !important;
}

.row-edited {
   background-color: orange !important;
}

.row-new {
   background-color: lightgreen !important;
}
.ag-row.row-new.ag-row-selected {
   background-color: lightgreen !important;
}
.ag-row.row-new.ag-row-focus {
   background-color: lightgreen !important;
}
.row-deleted {
   background-color: lightcoral !important;
}
.ag-row.row-deleted.ag-row-selected {
   background-color: lightcoral !important;
}
.ag-row.row-deleted.ag-row-focus {
   background-color: lightcoral !important;
}
.dark-mode .row-deleted {
   color: rgb(242, 242, 242) !important;
}

/* make the ag grid table display the full height of the table returned */
.ag-root-wrapper-body.ag-layout-normal.ag-focus-managed {
   height: 100%;
}

.ag-row-focus {
   background-color: rgba(99, 102, 241, 0.12) !important;
}

.ag-row-selected::before {
   background-color: rgba(99, 102, 241, 0.12) !important;
}

.ag-row-hover:not(.ag-full-width-row)::before,
.ag-row-hover.ag-full-width-row.ag-row-group::before {
   background-color: rgba(99, 102, 241, 0.06) !important;
}

.ag-row-hover.ag-row-selected::before {
   background-image: none !important;
}

.ag-column-hover {
   background-color: rgba(99, 102, 241, 0.06) !important;
}

.ag-header-cell.ag-header-active {
   .ag-header-menu-icon {
      opacity: 0.25 !important;
   }
}

.ag-sort-indicator-container {
   opacity: 0.25 !important;
}

.ag-row {
   /* This is the font size for text in the table rows */
   font-size: 12px !important;
}

.ag-theme-alpine .ag-row {
}

.ag-theme-alpine-dark {
   background-color: #0b0f19;
}
.ag-theme-alpine-dark .ag-row {
   color: #9397ad;
   border-bottom: 1px solid rgba(36, 50, 72, 0.6) !important;
}

.ag-theme-alpine-dark .ag-header-cell-text {
   color: #9397ad;
}

.ag-theme-alpine-dark .ag-paging-panel {
   color: #9397ad;
}

.ag-header {
   background-color: rgba(36, 50, 72, 0.05) !important;
   border-bottom: 1px solid rgba(36, 50, 72, 0.15) !important;
}

.ag-center-cols-viewport {
   background-color: rgba(36, 50, 72, 0.01);
}

.ag-theme-alpine-dark .ag-header {
   background-color: #0e1927 !important;
   border-bottom: 1px solid rgba(36, 50, 72, 1) !important;
}

.ag-theme-alpine-dark .ag-row-even {
   background-color: rgba(11, 15, 25, 0.9);
}

.ag-theme-alpine-dark .ag-row-odd {
   /*background-color: rgba(255, 255, 255, 0.04)*/
   background-color: rgba(11, 15, 25, 0.5);
}

.ag-theme-alpine-dark .ag-root-wrapper,
.ag-sticky-top,
.ag-dnd-ghost {
   /* background-color: var(--ag-background-color); */
   background-color: transparent;
}

.workbook .ag-chart-wrapper {
   border-radius: 6px;
   border: 1px solid #babfc7;
}

.workbook .ag-root-wrapper {
   box-shadow: none !important;
}

.ag-status-bar {
   line-height: 1 !important;
   background-color: rgba(36, 50, 72, 0.05) !important;
   border-top: 1px solid rgba(36, 50, 72, 0.15) !important;
   flex-shrink: 0;
}

.ag-side-bar {
   background-color: #fff !important;
}

.ag-menu {
   background-color: #fff !important;
}

.ag-menu-header {
   background-color: #fff !important;
}

.ag-column-panel {
   background-color: #fff !important;
}

.ag-group-container {
   background-color: #fff !important;
}

.ag-mini-filter {
   background-color: #fff !important;
}

.ag-filter-toolpanel-instance-filter {
   background-color: #fff !important;
}

.ag-set-filter-list {
   background-color: #fff !important;
}

.ag-filter-toolpanel-search {
   background-color: #fff !important;
}

.ag-tool-panel-wrapper {
   background-color: #fff !important;
}

.ag-panel-title-bar {
   background-color: #fff !important;
}

.ag-chart-menu .ag-button {
   background-color: #fff !important;
}

.ag-chart-menu-panel {
   background-color: #fff !important;
}

.dark-mode .ag-status-bar {
   background-color: #000 !important;
   border-top: 1px solid rgba(36, 50, 72, 0.9) !important;
}

.dark-mode .ag-side-bar {
   background-color: #000 !important;
}

.dark-mode .ag-menu {
   //background-color: rgba(255, 255, 255, 0.01) !important;
   background-color: #000 !important;
}

.dark-mode .ag-menu-header {
   background-color: #000 !important;
}

.dark-mode .ag-column-panel {
   background-color: #000 !important;
}

.dark-mode .ag-group-container {
   background-color: #000 !important;
}

.dark-mode .ag-mini-filter {
   background-color: #000 !important;
}

.dark-mode .ag-filter-toolpanel-instance-filter {
   background-color: #000 !important;
}

.dark-mode .ag-set-filter-list {
   background-color: #000 !important;
}

.dark-mode .ag-filter-toolpanel-search {
   background-color: #000 !important;
}

.dark-mode .ag-tool-panel-wrapper {
   background-color: #000 !important;
}

.dark-mode .ag-panel-title-bar {
   background-color: #000 !important;
}

.dark-mode .ag-chart-menu .ag-button {
   background-color: #000 !important;
}

.dark-mode .ag-chart-menu-panel {
   background-color: #000 !important;
}

.dark-mode .ag-chart-menu {
   background-color: transparent !important;
   color: #000;
   padding: 3px;
   border-radius: 2px;
}

.dark-mode .ag-chart-menu-icon {
   color: #4c82f7;
}

.ag-group-title-bar {
   background-color: #f4f5f6;
}

.dark-mode .ag-group-title-bar {
   background-color: #000 !important;
}

.dark-mode .ag-charts-canvas {
   background-color: transparent !important;
}

.ag-rtl .ag-side-bar-left,
.ag-ltr .ag-side-bar-right {
   border-left: 1px solid rgba(36, 50, 72, 0.1) !important;
}

.dark-mode .ag-rtl .ag-side-bar-left,
.dark-mode .ag-ltr .ag-side-bar-right {
   border-left: 1px solid rgba(36, 50, 72, 0.9) !important;
}

.dark-mode .ag-header-viewport {
   background-color: #000 !important;
}

/*
*   End - AG Grid customization
*/

.collapse-query-window {
   border-bottom-left-radius: 0 !important;
   border-top-right-radius: 0 !important;
}

.collapse-query-window-right {
   border-top-right-radius: 0.25rem !important;
   border-top-left-radius: 0 !important;
   border-bottom-right-radius: 0.25rem !important;
   border-bottom-left-radius: 0.25rem !important;
}

/*
*
*  React-Select style customization
*
*/

.runql-select {
   font-size: 0.75rem;
   color: #565973;
}

.dark-mode .runql-select {
   color: #9397ad;
}

.runql-select-invalid .runql-select__control {
   border-color: #dc3545 !important;
}

.dark-mode .runql-select-invalid .runql-select__control {
   border-color: #dc3545 !important;
}

.runql-select__control {
   border-color: rgb(212, 215, 229) !important;
}

.dark-mode .runql-select__input {
   color: rgb(212, 215, 229) !important;
}

.dark-mode .runql-select__control {
   box-shadow: none !important;
   background-color: transparent !important;
   border-color: #33354d !important;
}

.runql-select__control:hover {
   box-shadow: none !important;
   border-color: rgb(212, 215, 229) !important;
}

.dark-mode .runql-select__control:hover {
   box-shadow: none !important;
   border-color: #33354d !important;
}

.dark-mode .runql-select__indicator {
   color: #33354d !important;
}

.dark-mode .runql-select__indicator-separator {
   background-color: #33354d !important;
}

.dark-mode .runql-select__menu {
   background-color: transparent;
}

.dark-mode .runql-select__menu-list {
   background-color: #0e1927;
   border-radius: 4px;
   border: 1px solid #33354d !important;
}

.dark-mode .runql-select__single-value {
   color: #b4b7c9;
}

.runql-select__option {
   padding: 4px 12px !important;
}

.runql-select__option--is-focused {
   background-color: rgba(99, 102, 241, 0.12) !important;
}

.runql-select__option--is-selected {
   background-color: rgba(99, 102, 241, 0.5) !important;
}

.dark-mode .runql-select__option--is-selected {
   background-color: rgba(99, 102, 241, 0.5) !important;
}

/*
*
*  End React-Select style customization
*
*/

.typeselect-input-field {
   border: 1px solid;
   border-radius: 5px;
   height: 36.4px;
   font-size: 12px;
   color: #565973;
   flex-grow: 1;
   box-sizing: border-box;
   padding-left: 14px;
   padding-right: 30px;
   width: 100%;
   &:focus {
      outline: none;
      border-color: #6a6ce1;
      box-shadow: 0 0 5px rgba(141, 143, 238, 0.682);
   }
   &.error {
      border-color: rgb(239, 68, 68);
   }

   &.normal {
      border-color: #d4d7e5;
   }
}

.dark-mode .typeselect-input-field {
   &:focus {
      outline: 0.5px solid gray;
      border-color: none;
      box-shadow: none;
   }
   &.error {
      border-color: #dc3545;
   }
}

.typeselect-dropdown {
   border: 1px solid #d4d7e5;
   border-radius: 5px;
   position: absolute;
   background: white;
   z-index: 999;
   width: 100%;
   max-height: 150px;
   overflow-y: auto;
   margin-top: 2px;
}

.dark-mode .typeselect-dropdown {
   background-color: #0e1927;
   border-color: #33354d;

   &::-webkit-scrollbar {
      width: 8px;
      border-radius: 5px;
   }

   &::-webkit-scrollbar-track {
      background: #0e1927;
   }

   &::-webkit-scrollbar-thumb {
      background-color: #33354d;
   }

   &::-webkit-scrollbar-thumb:hover {
      background-color: #565973;
   }
}

.typeselect-error-icon {
   position: absolute;
   right: 10px;
   top: 50%;
   transform: translateY(-50%);
   color: rgb(239, 68, 68);
   pointer-events: none;
}

.dark-mode .typeselect-error-icon {
   color: #dc3545;
}

/*
*
*  React-Select auto suggestion style customization
*
*/

.runql-select-suggestion {
   font-size: 12px;
   color: #565973;
}

.dark-mode .runql-select-suggestion {
   color: #9397ad;
}

.runql-select-suggestion__control {
   box-shadow: none !important;
   border: none !important;
   min-height: 14px !important;
   background-color: transparent !important;
}

.runql-select-suggestion__input-container {
   margin: 0 !important;
   padding: 0 !important;
}

.dark-mode .runql-select-suggestion__control {
   background-color: transparent !important;
}

.dark-mode .runql-select-suggestion__input {
   color: white !important;
}

.runql-select-suggestion__indicator {
   color: transparent !important;
   width: 1px !important;
   line-height: 1px !important;
   padding: 0 !important;
}

.runql-select-suggestion__indicator-separator {
   background-color: transparent !important;
   width: 1px !important;
   line-height: 1px !important;
   padding: 0 !important;
}

.dark-mode .runql-select-suggestion__menu {
   background-color: transparent;
}

.dark-mode .runql-select-suggestion__menu-list {
   background-color: #0e1927;
   border-radius: 4px;
   border: 1px solid #33354d !important;
}

.dark-mode .runql-select-suggestion__single-value {
   color: #b4b7c9;
}

.runql-select-suggestion__option {
   padding: 4px 12px !important;
}

.runql-select-suggestion__single-value .title {
   font-style: italic;
   display: inline;
   margin-right: 0.5em;
}

.runql-select-suggestion__single-value .query {
   font-family: 'JetBrains Mono', monospace;
   font-size: 11px !important;
   display: inline;
}

.runql-select-suggestion__option .title {
   font-style: italic;
}

.runql-select-suggestion__option .query {
   font-family: 'JetBrains Mono', monospace;
   font-size: 11px !important;
}

.runql-select-suggestion__option--is-focused {
   background-color: rgba(99, 102, 241, 0.12) !important;
}

.runql-select-suggestion__option--is-selected {
   background-color: rgba(99, 102, 241, 0.5) !important;
}

.dark-mode .runql-select-suggestion__option--is-selected {
   background-color: rgba(99, 102, 241, 0.5) !important;
}

/*
*
*  End React-Select auto suggestion style customization
*
*/

.custom-card-transparent {
   background-color: transparent !important;
}

.suggested-buttons .btn:disabled {
   opacity: 0.3 !important;
}

.diff-viewer {
   font-family: 'JetBrains Mono', monospace;
   padding: 8px 0;
}

.diff-viewer tr {
   vertical-align: middle !important;
}

.diff-viewer td {
   padding: 0 !important;
   border: none;
}

.diff-viewer pre {
   padding: 0 2px 0 8px !important;
   line-height: 16px !important;
   border: none;
   box-shadow: none !important;
   font-family: 'JetBrains Mono', monospace;
}

.diff-viewer pre span {
   padding-top: 0 !important;
   padding-bottom: 0 !important;
}

/*.diff-viewer:not(pre) > code[class*='language-'],
pre[class*='language-'],
pre {
   --si-pre-border-width: 0;
   --si-pre-border-color: transparent;
   --si-pre-bg: transparent;
   --si-pre-line-numbers-border-width: 0;
   --si-pre-line-numbers-border-color: transparent;
   --si-pre-line-numbers-color: transparent;
   --si-pre-tag-color: transparent;
   --si-pre-comment-color: transparent;
   --si-pre-attr-name-color: transparent;
   --si-pre-attr-value-color: transparent;
   --si-pre-class-name-color: transparent;
   max-width: 100%;
   margin: 0;
   padding: 1px;
   background-color: transparent;
   border: none;
   border-radius: 0;
   font-size: 10px;
}*/

.diff-viewer:not(pre) > code[class*='language-'],
pre[class*='language-'],
pre {
   background-color: transparent;
   //border-color: #33354d;
   box-shadow: none !important;
}

.form-control {
   min-height: calc(1.6em + 0.95rem + 2px);
   padding: 0.475rem 0.875rem;
   font-size: 0.75rem;
   border-radius: 0.25rem;
}

.form-select {
   padding-top: 0.475rem;
   padding-bottom: 0.475rem;
   padding-left: 0.875rem;
   font-size: 0.75rem;
   border-radius: 0.25rem;
}

.form-control-plaintext {
   color: #565973;
}

.dark-mode .form-control-plaintext {
   color: rgba(255, 255, 255, 0.7);
}

.dark-mode .bs-tooltip-end .tooltip-arrow::before {
   border-right-color: #33354d !important;
}

.dark-mode .bs-tooltip-start .tooltip-arrow::before {
   border-left-color: #33354d !important;
}

.dark-mode .bs-tooltip-top .tooltip-arrow::before {
   border-top-color: #33354d !important;
}

.dark-mode .bs-tooltip-bottom .tooltip-arrow::before {
   border-bottom-color: #33354d !important;
}

.person-img-rounded {
   font-size: 10px;
   border-radius: 50%;
   height: 24px;
   width: 24px;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px solid #b4b7c9;
   color: #b4b7c9;
}

.dark-mode .person-img-rounded {
   font-size: 10px;
   border-radius: 50%;
   height: 24px;
   width: 24px;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px solid #565973;
   color: #565973;
}

.img-rounded-small .person-img-rounded {
   font-size: 8px;
   border-radius: 50%;
   height: 20px;
   width: 20px;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px solid #b4b7c9;
   color: #b4b7c9;
}

.dark-mode .img-rounded-small .person-img-rounded {
   font-size: 8px;
   border-radius: 50%;
   height: 20px;
   width: 20px;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px solid #565973;
   color: #565973;
}

.person-img-rounded-settings {
   border-radius: 50%;
   height: 24px;
   width: 24px;
   margin-right: 4px;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px solid #b4b7c9;
}

.dark-mode .person-img-rounded-settings {
   border-radius: 50%;
   height: 24px;
   width: 24px;
   margin-right: 4px;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px solid #565973;
}

.workspace-img-square {
   font-size: 14px;
   border-radius: 6px;
   height: 36px;
   width: 36px;
   margin-bottom: 4px;
   margin-right: 4px;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px solid #b4b7c9;
   color: #b4b7c9;
}

.dark-mode .workspace-img-square {
   font-size: 14px;
   border-radius: 6px;
   height: 36px;
   width: 36px;
   margin-right: 4px;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px solid #565973;
   color: #565973;
}

.img-square-small.workspace-img-square {
   font-size: 10px;
   border-radius: 4px;
   height: 24px;
   width: 24px;
   margin-right: 4px;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px solid #b4b7c9;
   color: #b4b7c9;
}

.dark-mode .img-square-small.workspace-img-square {
   font-size: 10px;
   border-radius: 4px;
   height: 24px;
   width: 24px;
   margin-right: 4px;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px solid #565973;
   color: #565973;
}

.collaborator-settings-icon {
   opacity: 0.5;
}

.collaborator-settings-icon:hover {
   opacity: 1;
}

.fw-500 {
   font-weight: 500;
}

.fw-600 {
   font-weight: 600;
}

.fw-800 {
   font-weight: 800;
}

.show-dropdown-arrow .dropdown-toggle::after {
   display: block !important;
}

.show-dropdown-arrow .dropdown-toggle-split {
   border-left: 1px solid #d4d7e5;
}

.dark-mode .show-dropdown-arrow .dropdown-toggle-split {
   border-left: 1px solid #33354d;
}

.save-button.dropdown-toggle-split {
   border-left: 1px solid #d4d7e5;
}

@keyframes show-description {
   from {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
   }
   to {
      // text-overflow: unset;
      overflow: visible;
      white-space: normal;
   }
}

.workspace-card-description {
   text-overflow: ellipsis;
   overflow: hidden;
   white-space: nowrap;

   &:hover {
      animation: show-description 1s ease-in forwards;
      animation-delay: 0s;
   }
}

.parallax-custom-1 {
   max-width: 574px;
   transform: translate3d(0px, 0px, 0px) rotate(0.0001deg);
   transform-style: preserve-3d;
   backface-visibility: hidden;
   overflow: hidden;
   position: relative;
}

.parallax-layer-custom-1 {
   transform: translate3d(-7.7px, 4px, 0px);
   transform-style: preserve-3d;
   backface-visibility: hidden;
   position: relative;
   display: block;
   left: 0px;
   top: 0px;
   content: url('/assets/img/404/light/layer01.png');
   transition: transform 1s ease-out;
}

.parallax-custom-1:hover .parallax-layer-custom-1 {
   transform: translate3d(1.1px, -1px, 0px);
   transform-style: preserve-3d;
   backface-visibility: hidden;
   position: relative;
   display: block;
   left: 0px;
   top: 0px;
   transition: transform 1s ease-out;
}

.parallax-layer-custom-2 {
   transform: translate3d(6.2px, -3.2px, 0px);
   transform-style: preserve-3d;
   backface-visibility: hidden;
   position: absolute;
   display: block;
   left: 0px;
   top: 0px;
   content: url('/assets/img/404/light/layer02.png');
   transition: transform 1s ease-out;
}

.parallax-custom-1:hover .parallax-layer-custom-2 {
   transform: translate3d(-0.3px, 1.5px, 0px);
   transform-style: preserve-3d;
   backface-visibility: hidden;
   position: absolute;
   display: block;
   left: 0px;
   top: 0px;
   transition: transform 1s ease-out;
}

.parallax-layer-custom-3 {
   transform: translate3d(-6.2px, 3.2px, 0px);
   transform-style: preserve-3d;
   backface-visibility: hidden;
   position: absolute;
   display: block;
   left: 0px;
   top: 0px;
   content: url('/assets/img/404/light/layer03.png');
   transition: transform 1s ease-out;
}

.parallax-custom-1:hover .parallax-layer-custom-3 {
   transform: translate3d(0.3px, -1.5px, 0px);
   transform-style: preserve-3d;
   backface-visibility: hidden;
   position: absolute;
   display: block;
   left: 0px;
   top: 0px;
   transition: transform 1s ease-out;
}

.dark-mode .parallax-layer-custom-1 {
   transform: translate3d(-7.7px, 4px, 0px);
   transform-style: preserve-3d;
   backface-visibility: hidden;
   position: relative;
   display: block;
   left: 0px;
   top: 0px;
   content: url('/assets/img/404/dark/layer01.png');
   transition: transform 1s ease-out;
}

.dark-mode .parallax-layer-custom-2 {
   transform: translate3d(6.2px, -3.2px, 0px);
   transform-style: preserve-3d;
   backface-visibility: hidden;
   position: absolute;
   display: block;
   left: 0px;
   top: 0px;
   content: url('/assets/img/404/dark/layer02.png');
   transition: transform 1s ease-out;
}

.dark-mode .parallax-layer-custom-3 {
   transform: translate3d(-6.2px, 3.2px, 0px);
   transform-style: preserve-3d;
   backface-visibility: hidden;
   position: absolute;
   display: block;
   left: 0px;
   top: 0px;
   content: url('/assets/img/404/dark/layer03.png');
   transition: transform 1s ease-out;
}

/*
*
* Custom badges for workspace log and global log
*
*/

.custom-badge-created {
   color: #22c55e !important;
   background-color: rgba(34, 197, 94, 0.12) !important;
}

.custom-badge-modified {
   color: #ffba08 !important;
   background-color: rgba(255, 186, 8, 0.12) !important;
}

.custom-badge-deleted {
   color: #ef4444 !important;
   background-color: rgba(239, 68, 68, 0.12) !important;
}

.custom-badge-workspace {
   color: #4c82f7 !important;
   background-color: rgba(76, 130, 247, 0.12) !important;
}

.custom-badge-saved.query {
   color: #4c82f7 !important;
   background-color: rgba(99, 102, 241, 0.12) !important;
}

.multi-line-text-container {
   line-height: 20px;
   overflow: hidden;
}

.truncate-lines {
   display: -webkit-box;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
}

.truncate-lines-2 {
   -webkit-line-clamp: 2;
}

.queryDescriptionWidth {
   max-width: 96%;
}

.div-form-label {
   margin-bottom: 0.3125rem;
}

.div-form-control {
   padding-top: 0.625rem;
   padding-right: 0px;
   padding-bottom: 0.625rem;
   padding-left: 0px;
   margin-bottom: 0;
}

.div-form-control-switch {
   padding-top: 0.15rem;
   padding-bottom: 0.15rem;
}

.navbar-no-padding {
   padding-top: 0 !important;
   padding-bottom: 0 !important;
}

.nav-link {
   font-weight: 500 !important;
}

.expand-button {
   line-height: 1;
   border: none;
   white-space: nowrap;
   overflow: hidden;
   background: none;
   display: flex;
   justify-content: center;
   padding: 0.5em 1em;
   align-items: center;
   color: #9397ad;
}

.text-box-bottom-border-only {
   background: transparent;
   padding: 0 0;
   border-radius: 0;
   border: none;
   border-bottom: 1px solid rgb(212, 215, 229);
   outline: none;
   box-shadow: none;
   min-height: 0;
}

.tab-custom-tooltip {
   max-width: 100%;
   max-height: 50vh;
   .popover-header {
      padding: 4px 10px;
   }
   .popover-body {
      padding: 4px 10px;
   }
}

.tab-code-block {
   font-family: 'JetBrains Mono', monospace;
   margin: 0;
   text-align: left;
}

.panelHandleLine {
   border-style: solid;
   border-color: rgba(36, 50, 72, 0.3);
   border-width: 0;
}

.dark-mode .panelHandleLine {
   border-style: solid;
   border-color: rgba(36, 50, 72, 0.1);
   border-width: 0;
}

.panelHandle {
   z-index: 1;
}

.panelHandle.vertical {
   width: 5px;
   margin-left: -2px;
   margin-right: -2px;
   .panelHandleLine {
      height: 100%;
      width: 0px;
      margin-left: 2px;
      border-left-width: 1px;
   }
}

.highcharts-dark .highcharts-background {
   fill: rgba(36, 50, 72, 0.005) !important;
}

.highcharts-dark svg text,
.highcharts-dark .highcharts-title text,
.highcharts-dark .highcharts-subtitle text,
.highcharts-dark .highcharts-axis-title text,
.highcharts-dark .highcharts-axis-labels text,
.highcharts-dark .highcharts-legend-item text,
.highcharts-dark .highcharts-data-label text,
.highcharts-dark .highcharts-tooltip span {
   fill: white !important;
   color: white !important;
}

.panelHandle.horizontal {
   height: 5px;
   margin-top: -2px;
   margin-bottom: -2px;
   .panelHandleLine {
      width: 100%;
      height: 0;
      margin-top: 2px;
      border-top-width: 1px;
   }
}

.dark-mode .panelHandleLine {
   border-color: rgba(255, 255, 255, 0.4);
}

.border-bottom-line {
   border-bottom: 1px solid rgba(36, 50, 72, 0.05);
}

.border-top-line {
   border-top: 1px solid rgba(36, 50, 72, 0.05);
}

.dark-mode .border-bottom-line {
   border-bottom: 1px solid rgba(36, 50, 72, 0.5);
}

.dark-mode .border-top-line {
   border-top: 1px solid rgba(36, 50, 72, 0.5);
}

.dark-mode .border-top-line2 {
   border-top: 1px solid rgba(36, 50, 72, 0.3);
}

.border-bottom-line-white {
   border-bottom: 1px solid #fff;
}

.border-right-line {
   border-right: 1px solid rgba(36, 50, 72, 0.3);
}

.border-right-line-light {
   border-right: 1px solid rgba(36, 50, 72, 0.1);
}

.dark-mode .border-right-line {
   border-right: 1px solid rgba(36, 50, 72, 0.4);
}

.customWorkspaceNav {
   text-decoration: none !important;
   font-weight: 600;
}

.dropdownMenuSmall .dropdown-menu {
   min-width: 20px !important;
}

.dropdownMenuSmall.dropdown {
   //background-color: #f3f6ff;
   //background-color: transparent;
   //border-radius: 8px;
   //border: 1px solid #e2e5f1;
   border: none !important;
   padding: 2px 4px 2px 4px;
}

.dark-mode .dropdownMenuSmall.dropdown {
   //background-color: rgba(255, 255, 255, 0.08);
   //background-color: transparent;
   //border-radius: 4px;
   //border: 1px solid #33354d;
   padding: 2px 4px 2px 4px;
}

.dropdownMenuSmall .nav-link {
   //opacity: 0.6;
}

.dropdownMenuSmall .dropdown-item {
   //opacity: 0.6;
}

.dropdown-divider {
   display: block !important;
}

.fsBadge {
   font-size: 0.8125em;
}

.specialWhiteSpace {
   white-space: pre-line;
}

.card {
   border-radius: 0.25rem;
}

.card.clickable {
   cursor: pointer;
   &:hover {
      border-color: #3a68cb;
   }
}

#theme-mode {
   cursor: pointer;
}

.queryCardCode {
   background-color: transparent !important;
}

.query-card .cm-editor + .cm-editor {
   margin-top: 0.5rem;
   border-top: 1px solid rgba(0, 0, 0, 0.1);
   padding-top: 0.5rem !important;
}
.dark-mode .query-card .cm-editor + .cm-editor {
   border-color: rgba(255, 255, 255, 0.06);
}

.dark-mode .card.cardQueryBox {
   border-color: rgba(255, 255, 255, 0.06);
}

.query-card-container {
   container-type: inline-size;
}
.query-card-query {
   overflow: hidden;
}
.query-card {
   .query-card-content {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: min-content;
      .query-card-header {
         order: 0;
      }
      .query-card-query {
         order: 1;
      }
      .query-card-footer {
         order: 2;
         align-self: end;
      }
   }
}
.query-card.query-diff-card {
   .query-card-content {
      grid-template-columns: 1fr 1fr;
      column-gap: 0;
      .query-card-query {
         grid-column: 1 / -1; // Make this element span across both columns
      }
      .query-card-header,
      .query-card-footer {
         padding-left: 2rem;
      }
      .query-diff-added {
         background-color: rgba(34, 197, 94, 0.12) !important;
      }
      .query-diff-removed {
         background-color: rgba(239, 68, 68, 0.12) !important;
      }
   }
}
.cm-editor .code-viewer {
   font-size: 11px !important;
   .query-comment {
      color: #666;
   }
   .query-keyword {
      color: #569cd6;
   }
   .query-star {
      color: #fd7e14;
   }
   .query-literal {
      color: #198754;
   }
}
.collapsed-code-viewer .query-line {
   overflow: hidden;
   text-wrap: nowrap;
   text-overflow: ellipsis;
}

@container (min-width: 600px) {
   .query-card {
      .query-title {
         font-size: 14px;
      }
      .query-card-header {
         row-gap: 0.5rem !important;
      }
   }
}

.query-card-query {
   background-color: rgba(255, 255, 255, 0.9);
   border-right: 3px solid rgba(36, 50, 72, 0.04) !important;
}
.dark-mode .query-card-query {
   background-color: rgba(0, 0, 0, 0.9);
   border-left: none;
}

.query-card-highlight {
   background-color: rgba(255, 255, 255, 0.1);

   .btn-link {
      background-color: #fff;
      color: #4c82f7 !important;
      border-color: rgba(76, 130, 247, 0.4);
   }

   .btn-link:hover {
      background-color: rgba(76, 130, 247, 0.2);
      color: #3a68cb !important;
      border-color: #3a68cb;
   }

   .btn-link:active {
      background-color: rgba(76, 130, 247, 0.2);
      color: #3a68cb !important;
      border-color: #3a68cb;
   }

   .potential-badge {
      background-color: #6366f1;
      border-radius: 50rem;
      color: #fff !important;
      padding: 0.35em 0.6em;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
   }
}

.dark-mode .query-card-highlight {
   .btn-link {
      background-color: #000;
   }
}

.query-card {
   overflow: hidden;
   background-color: rgba(36, 50, 72, 0.04);
   border-radius: 2px;
   .btn-link {
      color: rgba(36, 50, 72, 0.5);
      background-color: rgba(76, 130, 247, 0.2);
      border-color: transparent;
      opacity: 75%;
   }
   &:hover {
      .btn-link {
         opacity: 100%;
      }
   }

   .btn-link:disabled {
      background-color: rgba(36, 50, 72, 0.4) !important;
      color: rgba(255, 255, 255, 0.2) !important;
   }

   .btn-link:hover {
      //background-color: #000;
      background-color: rgba(76, 130, 247, 0.3);
   }

   .query-card-action {
      background-color: rgba(36, 50, 72, 0.1);
      visibility: hidden;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding: 0 4px !important;
   }
}

.hover-button {
   visibility: hidden; /* The button is hidden by default */
   opacity: 0;
}

.hover-button:hover {
   visibility: visible;
   opacity: 1;
}

.query-card:hover {
   .hover-button {
      visibility: visible;
      opacity: 1;
   }
}

.query-card.query-card-hover {
   cursor: pointer;
   border-left: 3px solid rgba(76, 130, 247, 0.2) !important;

   &:hover:not(:has(.btn:hover)),
   &:has(.query-card-action:hover) {
      background-color: rgba(36, 50, 72, 0.06) !important;
      border-color: #4c82f7 !important;
      .query-card-action {
         visibility: visible;
      }
      .btn-white {
         background-color: rgba(255, 255, 255, 0.7) !important;
         color: #0e1927;
      }
      .hover-button {
         visibility: visible;
         opacity: 1;
      }
   }
}

.dark-mode .query-card {
   background-color: rgba(36, 50, 72, 0.1);
   border-radius: 2px;
   .btn-link {
      color: rgba(255, 255, 255, 0.85) !important;
      background-color: rgba(76, 130, 247, 0.2);
      border-color: transparent;
   }

   .btn-link:disabled {
      background-color: rgba(36, 50, 72, 0.4) !important;
      color: rgba(255, 255, 255, 0.2) !important;
   }

   .btn-link:hover {
      background-color: rgba(76, 130, 247, 0.3) !important;
   }
}

.dark-mode .query-card.query-card-hover {
   border-left: 3px solid rgba(76, 130, 247, 0.2) !important;
   &:hover:not(:has(.btn:hover)),
   &:has(.query-card-action:hover) {
      background-color: rgba(36, 50, 72, 0.3) !important;
      .btn-white {
         background-color: rgba(0, 0, 0, 0.7) !important;
         color: inherit;
      }
   }
}

.other-cards {
   //background-color: rgba(36,50,72, 0.2) !important;
   //background-color: rgba(36,50,72, 0.1) !important;
   border-left: 3px solid rgba(76, 130, 247, 0.2) !important;
   border-radius: 2px;
}

a.other-cards {
   text-decoration: none;
}

.other-cards:hover {
   background-color: rgba(36, 50, 72, 0.1) !important;
}

.dark-mode .other-cards {
   //background-color: rgba(36,50,72, 0.2) !important;
   background-color: rgba(36, 50, 72, 0.3) !important;
   border-left: 3px solid rgba(76, 130, 247, 0.2) !important;
   border-radius: 2px;
}

.dark-mode .other-cards:hover {
   background-color: rgba(36, 50, 72, 0.5) !important;
}

.query-review-page-queries .bottom-explore-tabs {
   border-top: 1px solid rgba(36, 50, 72, 0.05);
}

.dark-mode .query-review-page-queries .bottom-explore-tabs {
   border-top: 1px solid rgba(36, 50, 72, 0.3);
}

.query-review-page-queries > .query-card {
   border: 1px solid rgba(76, 130, 247, 0.3) !important;
}

.dark-mode .query-review-page-queries > .query-card {
   border: 1px solid rgba(76, 130, 247, 0.1) !important;
}

/* this is the query card inside a suggestion in the starting block*/
.starting-block {
   .dimmed,
   .dimmed-queries .query-card {
      opacity: 80%;
      transition: opacity 0.2s ease-in-out;
   }

   .dimmed,
   .dimmed-queries .query-card .certified {
      opacity: 70%;
   }

   .dimmed-query {
      opacity: 70%;
   }
   .hover-undimmer:hover .dimmed,
   .hover-undimmer:hover .dimmed-queries .query-card,
   .dimmed-queries:hover .query-card,
   .dimmed:hover {
      opacity: 100%;
   }

   .dimmed-queries:hover .dimmed-query {
      opacity: 80% !important;
   }

   .hover-undimmer:hover .certified {
      opacity: 100%;
   }

   .queryDisplayCardButtonActions {
      color: rgb(147, 151, 173);
   }

   .dimmed-queries .query-card .queryDisplayCardButtonActions {
      background-color: #fff;
      color: #4c82f7 !important;
      border-color: rgba(76, 130, 247, 0.3);
      opacity: 60%;
   }

   .dimmed-queries .query-card .queryDisplayCardButtonActions.btn-link:hover {
      background-color: rgba(76, 130, 247, 0.2);
      color: #3a68cb !important;
      border-color: rgba(76, 130, 247, 0.4);
   }

   .dimmed-queries .query-card .queryDisplayCardButtonActions.btn-link:active {
      background-color: rgba(76, 130, 247, 0.2);
      color: #3a68cb !important;
      border-color: rgba(76, 130, 247, 0.4);
   }

   .dimmed-queries .query-card:hover .queryDisplayCardButtonActions {
      opacity: 100%;
   }
}

@keyframes highlight {
   from {
      background-color: rgba(76, 130, 247, 0.3);
      box-shadow: 0px 0px 16px 8px rgba(76, 130, 247, 0.3);
   }
   to {
      background-color: transparent;
      box-shadow: none;
   }
}

@keyframes pulse {
   0% {
      background-color: rgba(76, 130, 247, 0.3);
      box-shadow: 0px 0px 24px 12px rgba(76, 130, 247, 0.3);
   }
   50% {
      background-color: rgba(76, 130, 247, 0.2);
      box-shadow: 0px 0px 24px 12px rgba(76, 130, 247, 0.2);
   }
   100% {
      background-color: rgba(76, 130, 247, 0.3);
      box-shadow: 0px 0px 24px 12px rgba(76, 130, 247, 0.3);
   }
}
.ai-sparkles-appear,
.ai-pulse {
   position: relative;
}
.ai-sparkles-appear:after {
   pointer-events: none;
   animation: highlight 2s forwards;
   content: '';
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
}
.ai-pulse:after {
   pointer-events: none;
   animation: pulse 0.75s ease-in-out infinite;
   content: '';
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
}
.hover-undimmer:not(:hover) .dimmed-queries .query-card.ai-sparkles-appear {
   animation: fade 4s forwards;
}

@keyframes fade {
   from {
      opacity: 100%;
   }
   to {
      opacity: 80%;
   }
}

.dark-mode .react-sigma {
   background-color: #33354d !important;
}

.neo4jCard {
   font-size: 13px;
   position: absolute;
   top: 0;
   right: 0;
   width: 350px;
   overflow-x: hidden;
   padding: 8px;
   background-color: #fff !important;
}

.dark-mode .neo4jCard {
   background-color: #0e1927 !important;
}

.dropdown-button > button {
   width: 100%;
   padding-top: 4px;
   padding-bottom: 4px;
}
.facet-dropdown-label {
   color: #444;
   text-overflow: ellipsis;
   font-size: 11px;
   font-weight: normal;
   padding: 0;
   justify-content: start;
   transition: font-weight 0.2s ease;
}

.facet-dropdown-label:hover {
   color: #000;
}

.facet-dropdown-label.disabled {
   color: #7c7c7c !important;
   cursor: default;
}

.dark-mode .facet-dropdown-label {
   color: #aaa;
   &:hover {
      color: #fff;
   }
}

.search-filters {
   display: flex;
   flex-direction: column !important;
}
.dropdown-container {
   display: flex;
   align-items: center;
   flex-wrap: wrap;
}

.query-list.two-up {
   display: grid !important;
   grid-template-columns: 1fr 1fr;
   row-gap: 0.5rem;
   column-gap: 0.5rem;
}
.home-page,
.query-list.two-up {
   .query-list {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
   }
   // These two values need to be updated when changing the layout of a query
   // card, unfortunately.
   .query-card-content {
      min-height: 249.6px;
   }
   .query-list-logs .query-card-content {
      min-height: 150.4px;
   }

   .query-card-query {
      padding: 1rem !important;
   }
   .query-card-footer,
   .query-card-header,
   .expand-button {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
   }
   .search-filters {
      width: 600px;
      max-width: 100%;
      margin: 0 auto;
      margin-bottom: 1rem;
   }
   .search-filters > input {
      font-size: 14px;
   }
   .dropdown-container {
      width: 100%;
      flex-wrap: wrap !important;
      display: flex;
   }
}

.btn-sm,
.btn-group-sm > .btn {
   padding-bottom: 0.25rem !important;
   padding-top: 0.25rem !important;
   padding-left: 0.5rem !important;
   padding-right: 0.5rem !important;
   line-height: 1;
   height: 22px;
}

.form-switch.mode-switch .form-check-input,
.form-switch.price-switch .form-check-input {
   background-color: #d4d7e5;
   border: 1px solid #d4d7e5;
   margin-left: 0;
   order: 2;
   box-shadow: none !important;
}

.dark-mode .form-switch.mode-switch .form-check-input,
.form-switch.price-switch .form-check-input {
   background-color: #0e1927;
   border: 1px solid #33354d;
   margin-left: 0;
   order: 2;
   box-shadow: none !important;
}

.icon-xs {
   height: 2rem;
}

.db-selector-off {
   background-color: transparent;
}

.dark-mode .db-selector-off {
   background-color: #33354d;
   color: #fff;
}

.db-selector-on {
   background-color: #4c82f7;
   color: #fff;
}

.dark-mode .db-selector-on {
   background-color: #4c82f7;
   color: #fff;
   border: 1px solid #fff;
}

.db-selector:hover {
   background-color: #4c82f7;
   color: #fff;
}

.db-selector:hover .db-selector-icon {
   filter: brightness(0) invert(1);
}

.db-selector-on .db-selector-icon {
   filter: brightness(0) invert(1);
}

.lh-20 {
   line-height: 20px;
}

.hover-highlight:hover {
   color: #3a68cb !important;
}

.hover-highlight:hover .text-body {
   color: #3a68cb !important;
}

.dark-mode .hover-highlight:hover {
   color: #fff !important;
}

.dark-mode .hover-highlight:hover .text-body {
   color: #fff !important;
}

//primary blues

.primary-blue {
   color: #4c82f7;
   //color: rgba(76,130,247,1);
}

.primary-blue-dark {
   color: #243248;
   //color: rgba(36,50,72,1);
}

.primary-blue-darkest {
   color: #0e1927;
   //color: rgba(14,25,39,1);
}

.btn-primary {
   --si-btn-active-bg: #3a68cb;
   --si-btn-active-border-color: var(--si-btn-active-bg);
   --si-btn-active-color: #fff;
   --si-btn-active-shadow: unset;
   --si-btn-bg: #4c82f7;
   --si-btn-border-color: var(--si-btn-bg);
   --si-btn-color: #fff;
   --si-btn-disabled-bg: rgba(36, 50, 72, 0.15);
   --si-btn-disabled-border-color: var(--si-btn-disabled-bg);
   --si-btn-disabled-color: #0e1927;
   --si-btn-focus-shadow-rgb: 84, 87, 205;
   --si-btn-hover-bg: #3a68cb;
   --si-btn-hover-border-color: var(--si-btn-hover-bg);
   --si-btn-hover-color: #fff;
}

.btn-primary:disabled {
   background-color: rgba(76, 130, 247, 0.1) !important;
   color: rgba(76, 130, 247, 1);
}

.dark-mode .btn-primary:disabled {
   border-color: rgba(255, 255, 255, 0.18);
}

.btn-secondary {
   --si-btn-color: #fff;
   --si-btn-hover-color: #fff;
   --si-btn-active-color: #fff;
   --si-btn-border-color: rgba(36, 50, 72, 0.1);
   --si-btn-hover-border-color: rgba(36, 50, 72, 0.1);
   --si-btn-active-border-color: rgba(36, 50, 72, 0.1);
   // Can't have transparency because it's shown on top of other items
   --si-btn-bg: rgba(152, 160, 169, 1);
   --si-btn-hover-bg: rgba(115, 125, 138, 1);
   --si-btn-active-bg: rgba(115, 125, 138, 1);
   --si-btn-focus-shadow-rgb: none;
   --si-btn-active-shadow: unset;

   --si-btn-disabled-bg: rgba(36, 50, 72, 0.05);
   --si-btn-disabled-border-color: var(--si-btn-disabled-bg);
}

.btn-secondary:disabled {
   color: rgba(76, 130, 247, 0.3) !important;
   border-color: rgba(76, 130, 247, 0.2);
}

.dark-mode .btn-secondary:disabled {
   background-color: rgba(36, 50, 72, 0.4) !important;
   color: rgba(255, 255, 255, 0.2) !important;
}

.dark-mode .btn-secondary {
   // Can't have transparency because it's shown on top of other items
   background-color: rgba(34, 53, 87, 1);
}

.dark-mode .btn-secondary:hover {
   // Can't have transparency because it's shown on top of other items
   background-color: rgba(39, 63, 109, 1);
}

.btn-outline-primary {
   --si-btn-color: rgba(36, 50, 72, 0.5);
   --si-btn-border-color: rgba(36, 50, 72, 0.1);
   --si-btn-hover-color: #fff;
   --si-btn-hover-bg: #4c82f7;
   --si-btn-hover-border-color: #3a68cb;
   --si-btn-focus-shadow-rgb: transparent;
   --si-btn-active-color: #3a68cb;
   --si-btn-active-bg: #4c82f7;
   --si-btn-active-border-color: #3a68cb;
   --si-btn-active-shadow: unset;
   --si-btn-disabled-color: #4c82f7;
   --si-btn-disabled-bg: transparent;
   --si-btn-disabled-border-color: #4c82f7;
   --si-gradient: none;
}

.dark-mode .btn-outline-primary {
   --si-btn-color: rgba(255, 255, 255, 0.25);
   --si-btn-border-color: rgba(255, 255, 255, 0.25);
   --si-btn-hover-color: #fff;
   --si-btn-hover-bg: #4c82f7;
   --si-btn-hover-border-color: #3a68cb;
   --si-btn-focus-shadow-rgb: transparent;
   --si-btn-active-color: #3a68cb;
   --si-btn-active-bg: #4c82f7;
   --si-btn-active-border-color: #3a68cb;
   --si-btn-active-shadow: unset;
   --si-btn-disabled-color: #4c82f7;
   --si-btn-disabled-bg: transparent;
   --si-btn-disabled-border-color: #4c82f7;
   --si-gradient: none;
}

.dark-mode .btn-outline-secondary {
   &:hover,
   &:active,
   &:focus,
   .show {
      color: #fff;
      background-color: rgba(39, 63, 109, 1);
      border-color: rgba(39, 63, 109, 1);
   }
}

.attachDataSourceButtonMargin {
   padding-bottom: 0.1rem !important;
   padding-top: 0.1rem !important;
}

.dropdown-item {
   font-size: 11px;
   line-height: 13px;
}

.text-primary {
   color: #4c82f7 !important;
}

:root {
   --si-link-color: rgba(36, 50, 72, 0.5);
   --si-link-hover-color: #0e1927;
   --si-heading-link-hover-color: #0e1927;
   --si-navbar-hover-color: #0e1927;
   --si-navbar-active-color: #0e1927;
}

.nav {
   --si-nav-link-color: rgba(36, 50, 72, 0.5);
   --si-nav-link-hover-color: #0e1927;
   --si-nav-link-active-color: #0e1927;
   --si-nav-link-disabled-color: rgba(36, 50, 72, 0.3);
}

.dark-mode {
   --si-link-color: rgba(255, 255, 255, 0.65);
   --si-link-hover-color: rgba(255, 255, 255, 1);
   --si-heading-link-hover-color: rgba(255, 255, 255, 1);
   --si-navbar-hover-color: rgba(255, 255, 255, 1);
   --si-navbar-active-color: rgba(255, 255, 255, 1);
   --si-gray-600: rgba(255, 255, 255, 0.5);
   --si-gray-700: rgba(255, 255, 255, 0.7);
   --si-gray-800: rgba(255, 255, 255, 0.85);
   --si-gray-900: #fff;
   --si-body-bg: #0b0f19;
   --si-body-color: rgba(255, 255, 255, 0.75);
   --si-secondary-bg: rgba(255, 255, 255, 0.04);
   --si-heading-link-color: rgba(255, 255, 255, 0.5);
   --si-heading-link-hover-color: #fff;
   --si-nav-link-color: rgba(255, 255, 255, 0.5);
   --si-border-color: rgba(255, 255, 255, 0.14);
   --si-code-color: #f33185;
}

.dark-mode .nav {
   --si-nav-link-color: rgba(255, 255, 255, 0.5);
   --si-nav-link-hover-color: #fff;
   --si-nav-link-active-color: #fff;
   --si-nav-link-disabled-color: rgba(255, 255, 255, 0.4);
}

.dark-mode .bg-secondary,
.dark-mode .card.bg-light {
   background-color: rgba(36, 50, 72, 0.2) !important;
}

.dark-mode .queryDisplayCard {
   background-color: rgba(36, 50, 72, 0.4) !important;
}

.bg-secondary {
   background-color: rgba(36, 50, 72, 0.05) !important;
}

.explore-tabs {
   font-size: 11px;
   background: rgba(36, 50, 72, 0.05);
   display: flex;
   flex-wrap: nowrap;
   flex-direction: row;
   flex-shrink: 1;
   .explore-tab {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
      border-right: 1px solid rgba(36, 50, 72, 0.05);
      a,
      button {
         line-height: 1;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
         color: inherit;
      }
      button {
         padding-left: 2px !important;
         padding-right: 8px !important;
      }
      a:hover,
      button:hover {
         color: #0e1927;
         letter-spacing: -0.07px;
      }
   }
   .explore-tab.active {
      background-color: #fff;
      a {
         color: #0e1927 !important;
      }
   }
}

.dark-mode .explore-tabs {
   background-color: rgba(36, 50, 72, 0.2);
   .explore-tab {
      background-color: rgba(36, 50, 72, 0.2);
      border-right: 1px solid rgba(36, 50, 72, 0.4);
      a:hover,
      button:hover {
         color: #fff !important;
      }
   }

   .explore-tab.active {
      background-color: #0b0f19;
      a {
         color: #fff !important;
      }
   }
}

.bottom-explore-tabs {
   border-bottom: 1px solid rgba(36, 50, 72, 0.05);
}

.bottom-explore-tabs {
   .nav-item,
   .explore-tab {
      margin-bottom: -1px;
      border-bottom: 1px solid transparent;
      &.active {
         border-bottom-color: rgb(255, 255, 255);
      }
   }
}

.dark-mode .bottom-explore-tabs {
   background-color: transparent;
   border-bottom: 1px solid rgba(36, 50, 72, 0.5);
   .explore-tab {
      background-color: rgba(36, 50, 72, 0.2);
      border-right: 1px solid rgba(36, 50, 72, 0.4);
      a:hover,
      button:hover {
         color: #fff !important;
      }
   }
   .explore-tab.active,
   .nav-item.active {
      border-bottom-color: rgb(11, 15, 25);
   }

   .bottom-explore-tab.active {
      background-color: #0b0f19;
      a {
         color: #fff !important;
      }
   }
}

.resultset-row {
   background-color: rgba(36, 50, 72, 0.05);
}

.steps-row {
   background-color: rgba(36, 50, 72, 0.05);
}

.dark-mode .resultset-row {
   background-color: #000;
}

.dark-mode .steps-row {
   background-color: #000;
}

.explore-tabs-scrollable {
   overflow-x: auto;
   scrollbar-width: none;
}

.explore-tabs .explore-tab.tab-no-right-border {
   border-right: none !important;
}

.text-muted .nav-link {
   color: inherit;
}

.bottom-left-main-actions {
   opacity: 0.6;
}

.dark-mode .bottom-left-main-actions {
   opacity: 0.5;
}

.bottom-left-logo {
   opacity: 0.6;
}

.radius-20p {
   border-radius: 20px;
}

.copy-icon {
   color: rgba(36, 50, 72, 0.2) !important;
}

.dark-mode .copy-icon {
   color: rgba(255, 255, 255, 0.1) !important;
   background-color: transparent !important;
}

.dark-mode .copy-icon:hover {
   color: rgba(255, 255, 255, 0.3) !important;
}

.workspace-description-bar {
   background-color: rgba(36, 50, 72, 0.15) !important;
   height: 28px;
}

.dark-mode .workspace-description-bar {
   background-color: rgba(36, 50, 72, 0.09) !important;
   color: #fff;
}

.starting-block-panel .nav-link.active {
   color: #0e1927;
   background-color: rgba(36, 50, 72, 0.1);
}

.starting-block-panel .nav-link:hover {
   color: #0e1927;
   background-color: rgba(36, 50, 72, 0.05);
}

.dark-mode .starting-block-panel .nav-link.active {
   color: #fff;
   background-color: rgba(255, 255, 255, 0.05);
}

.dark-mode .starting-block-panel .nav-link:hover {
   color: #fff;
   background-color: rgba(255, 255, 255, 0.03);
}

.small-form-control-input {
   padding: 1px 4px 1px 8px !important;
   min-height: 2.6em;
   font-size: 12px;
}

.search-max-width {
   max-width: 100%;
}

.subtle-input {
   border-color: transparent;
   background-color: transparent;
   &:hover,
   &:focus {
      background-color: #d4d7e5;
      border-color: #d4d7e5;
   }
}
.dark-mode .subtle-input {
   border-color: transparent;
   background-color: transparent;
   &:hover,
   &:focus {
      background-color: #0e1927;
      border-color: #33354d;
   }
}

.nav-rail {
   height: 100%;
   flex-shrink: 0;
   border-right: 1px solid rgba(36, 50, 72, 0.05);

   .nav-link {
      border-radius: 4px;
      padding: 10px;
      color: rgba(36, 50, 72, 0.5);
      margin: 4px;
   }
   .nav-link:hover:not(.sectionActive) {
      background-color: rgba(36, 50, 72, 0.05);
   }

   .nav-link.sectionActive {
      background-color: rgba(36, 50, 72, 0.1);
      color: #0e1927;
   }
}

.dark-mode .nav-rail {
   border-right: 1px solid rgba(36, 50, 72, 0.5);
   .nav-link {
      border-radius: 4px;
      padding: 10px;
      color: rgba(255, 255, 255, 0.5);
      margin: 4px;
   }

   .nav-link:hover:not(.sectionActive) {
      background-color: rgba(255, 255, 255, 0.05);
   }

   .nav-link.sectionActive {
      background-color: rgba(255, 255, 255, 0.1);
      color: #fff;
   }
}

.nav-link.secret {
   opacity: 0;
}
.nav-link.secret.sectionActive {
   opacity: 1;
}

.desktop header {
   -webkit-app-region: drag;
   a,
   button,
   .app-no-drag {
      -webkit-app-region: no-drag;
   }
}
.desktop header.header {
   padding-top: 6px !important;
   padding-bottom: 4px !important;
}
.top-explore-tabs.active a {
   -webkit-app-region: drag;
}

.bg-nav {
   background-color: rgba(36, 50, 72, 0.05);
}

.dark-mode .bg-nav {
   background-color: #0e1927;
}

.top-nav-bar-location-title {
   background-color: rgba(76, 130, 247, 0.3);
   //border-left: 3px solid rgba(76,130,247,1);
   border-radius: 3px;
   margin-left: 3px;
   margin-right: 3px;
   margin-top: 4px;
   margin-bottom: 4px;
}

.nav-link-left-menu.nav-link {
   //border-radius: 6px;
   padding: 10px;
   color: #243248;
   margin-left: 3px;
   margin-right: 3px;
   margin-top: 4px;
   margin-bottom: 4px;
}

.nav-link-left-menu.nav-link:hover:not(.active) {
   background-color: rgba(76, 130, 247, 0.3);
   //border-left: 3px solid rgba(76,130,247,1);
   border-radius: 3px;
}

.nav-link-left-menu.nav-link.active {
   background-color: rgba(76, 130, 247, 0.3);
   color: #243248;
   //border-left: 3px solid rgba(76,130,247,1);
   border-radius: 3px;
}

.dark-mode .nav-link-left-menu.nav-link:hover:not(.active) {
   background-color: rgba(255, 255, 255, 0.05);
}

.dark-mode .nav-link-left-menu.nav-link.active {
   background-color: rgba(255, 255, 255, 0.05);
   color: #fff !important;
}

.navigation-menu-width {
   min-width: 192px;
   max-width: 100%;
}

.activity-cards {
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
   gap: 24px;
}

.min-height-44 {
   min-height: 44px;
}

.padding-left-right-20 {
   padding-left: 20px !important;
   padding-right: 20px !important;
}

.margin-left-right-8 {
   margin-left: 8px;
   margin-right: 8px;
}

.margin-left-right-4 {
   margin-left: 4px;
   margin-right: 4px;
}

.btn-no-padding {
   padding: 0 !important;
}

.border-1 {
   border-color: rgba(36, 50, 72, 0.05);
}

.dark-mode .border-1 {
   border-color: rgba(36, 50, 72, 0.4);
}

.btn-success {
   background-color: rgba(34, 197, 94, 0.8) !important;
   border: 1px solid rgba(34, 197, 94, 0.05) !important;
}

.btn-success:hover {
   background-color: rgba(34, 197, 94, 1) !important;
   border: 1px solid rgba(34, 197, 94, 0.05) !important;
}

.dark-mode {
   .btn-success {
      background-color: rgba(34, 197, 94, 0.4) !important;
      border: 1px solid rgba(34, 197, 94, 0.05) !important;
   }

   .btn-success:hover {
      background-color: rgba(34, 197, 94, 0.7) !important;
      border: 1px solid rgba(34, 197, 94, 0.07) !important;
   }
}

.starting-block-border-top {
   border-top: 1px solid rgba(36, 50, 72, 0.2);
}

.dark-mode .starting-block-border-top {
   border-top: 1px solid rgba(36, 50, 72, 0.5);
}

/*this is to fix a formatting issue on home->Snippets for the bottom padding*/
.snippets .query-card {
   padding-bottom: 8px;
}

.snippets-card {
   font-family: 'JetBrains Mono';
   font-size: 12px;
   //border: 1px solid transparent;
   //border-radius: 0.25rem;
   padding: 0.5rem;
   cursor: pointer;
}

.snippets-top-title-starting-block {
   //padding: 4px 0 2px 1px;
   color: #0e1927 !important;
}

.snippets-top-title-starting-block-underline {
   border-bottom: 1px solid #0e1927;
}

.dark-mode .snippets-top-title-starting-block {
   //padding: 4px 0 2px 1px;
   color: #fff !important;
}

.dark-mode .snippets-top-title-starting-block-underline {
   border-bottom: 1px solid #fff;
}

.button-social-signin {
   border: 1px solid rgba(36, 50, 72, 0.2) !important;
   background-color: #fff !important;
   color: #0e1927 !important;
   box-shadow: none !important;
   font-size: 16px !important;
   border-radius: 6px !important;
   :hover {
      color: #3a68cb;
   }
}

.dark-mode .button-social-signin {
   border: 1px solid rgba(36, 50, 72, 0.4) !important;
   background-color: #0e1927 !important;
   color: #fff !important;
   box-shadow: none !important;
   font-size: 16px !important;
   :hover {
      color: #3a68cb;
   }
}

.button-social-signin div {
   display: inline-flex !important;
}

.badge.bg-info {
   background-color: rgba(36, 50, 72, 0.09) !important;
   color: #565973;
}

.dark-mode .badge.bg-info {
   background-color: rgba(36, 50, 72, 0.9) !important;
   color: rgba(255, 255, 255, 0.45);
}

.badge.bg-tag {
   background-color: rgba(255, 255, 255, 0.3) !important;
   color: #b4b7c9;
}

.dark-mode .badge.bg-tag {
   background-color: rgba(0, 0, 0, 0.2) !important;
   color: rgba(255, 255, 255, 0.45);
}

.badge.bg-success {
   background-color: rgba(34, 197, 94, 0.5) !important;
   color: #565973;
}

.dark-mode .badge.bg-success {
   background-color: rgba(34, 197, 94, 0.5) !important;
   color: black;
}

.lock-bg-success {
   background-color: rgba(34, 197, 94, 0.5) !important;
   color: #565973;
}

.dark-mode .lock-bg-success {
   background-color: rgba(34, 197, 94, 0.5) !important;
   color: black;
}

.lock-text-success {
   color: rgba(34, 197, 94, 0.5) !important;
}

.dark-mode .lock-text-success {
   color: rgba(34, 197, 94, 0.5) !important;
}

.bg-primary {
   background: #4c82f7 !important;
   color: white;
}

.card.bg-primary {
   background: #5b90ff !important;
   .text-muted {
      color: #ddd !important;
   }
}
.dark-mode {
   .card.bg-primary {
      background: #355db2 !important;
   }
}

.lock-bg-primary {
   background-color: rgba(36, 50, 72, 0.5) !important;
}

.dark-mode .lock-bg-primary {
   background-color: rgba(255, 255, 255, 0.25) !important;
}

.py-workspace-description {
   min-height: 28px !important;
   height: 28px !important;
}

.show-hide-on-hover {
   .show-on-hover {
      display: none;
   }
   .hide-on-hover {
      display: block;
   }
   &:hover {
      .show-on-hover {
         display: block;
      }
      .hide-on-hover {
         display: none;
      }
   }
}
//#0E1927
//#0b0f19

.query-page-background {
   background-color: rgba(36, 50, 72, 0.005);
}

.dark-mode .query-page-background {
   //background-color: rgba(36, 50, 72, 0.07);
   background-color: #0b0f19;
}

.query-page-button-bar {
   background-color: rgba(36, 50, 72, 0.005);
}

.dark-mode .query-page-button-bar {
   //background-color: rgba(36, 50, 72, 0.2);
   background-color: #0b0f19;
}

.code-view-icon-overlay {
   opacity: 50%;
}

.query-card-top-right {
   position: absolute;
   top: 0;
   right: 0;
}

.dimmed-queries .save-snippet-card .query-card {
   opacity: 100% !important;
   border-left: 3px solid #fff !important;
}

.cursor-pointer {
   cursor: pointer;
}

.save-snippet-cursor {
   cursor: pointer;
}

.css-cncyp1-word-diff {
   padding: 0 !important;
}

/* fix for the bottom arrow of top popovers */
.bs-popover-top {
   .popover-arrow {
      margin-left: -8px;
   }
}

/* context menu items for right clicking in the navigator panel */
.contextmenu {
   width: auto !important;
}

.contextmenu__item {
   padding: 5px 15px !important;
}

.dark-mode .contextmenu {
   background-color: #243248;
   color: #fff;
}

.dark-mode .contextmenu__item:hover:not(.contextmenu__item--disabled) {
   background-color: #000000 !important;
}

.runa-suggestion-buttons {
   opacity: 0.75;
   padding: 12px 8px 12px 8px !important;
}

.runa-new-chat-button {
   opacity: 0.75;
}

.other-cards.disabled {
   cursor: not-allowed;
   opacity: 0.5;
   pointer-events: none;
}

.rainbowAnimation {
   border-radius: 2px;
   border: 2px dotted transparent;
   background-image: linear-gradient(to right, rgb(255 255 255 / 1), rgb(255 255 255 / 1)),
      conic-gradient(
         from var(--angle),
         #d53e33 0deg 90deg,
         #fbb300 90deg 180deg,
         #4c82f7 180deg 270deg,
         #399953 270deg 360deg
      );
   background-origin: border-box;
   background-clip: padding-box, border-box;
}

.query-page-button-bar .rainbowAnimation {
   border-radius: 6px;
   background-image: linear-gradient(to right, rgb(255 255 255 / 0.85), rgb(255 255 255 / 0.85)),
      conic-gradient(
         from var(--angle),
         #d53e33 0deg 90deg,
         #fbb300 90deg 180deg,
         #4c82f7 180deg 270deg,
         #399953 270deg 360deg
      );
   .btn-dark {
      background-color: transparent;
      color: #0e1927;
   }
}

.dark-mode .rainbowAnimation {
   background-image: linear-gradient(
         to right,
         rgb(11, 15, math.div(25, 1)),
         rgb(11, 15, math.div(25, 1))
      ),
      conic-gradient(
         from var(--angle),
         #d53e33 0deg 90deg,
         #fbb300 90deg 180deg,
         #4c82f7 180deg 270deg,
         #399953 270deg 360deg
      );
}

.dark-mode .query-page-button-bar .rainbowAnimation {
   border-radius: 6px;
   background-image: linear-gradient(
         to right,
         rgb(11, 15, math.div(25, 1)),
         rgb(11, 15, math.div(25, 1))
      ),
      conic-gradient(
         from var(--angle),
         #d53e33 0deg 90deg,
         #fbb300 90deg 180deg,
         #4c82f7 180deg 270deg,
         #399953 270deg 360deg
      );
   .btn-dark {
      background-color: #fff;
   }
}
.btn-dark {
   border: 0px solid transparent !important;
}

/*@property --opacity {
  syntax: "<number>";
  initial-value: 0.5;
  inherits: false;
}*/

@property --angle {
   syntax: '<angle>';
   initial-value: 0deg;
   inherits: false;
}

@keyframes rainbowOpacityChange {
   to {
      --opacity: 1;
   }
}

@keyframes rainbowRotate {
   to {
      --angle: 360deg;
   }
}

.rainbowAnimation {
   animation: rainbowRotate 4s linear infinite, rainbowOpacityChange 3s infinite alternate;
}

.error-boundary {
   height: 100%;
   display: flex;
   flex-direction: column;
   width: 100%;
   justify-content: center;
   padding: 1rem 0;
}

// -- Choose SSH key file input group

.input-group button.chooseFileButton {
   padding: 7.6px 14px;
   font-weight: normal;
   color: $body-color;
   background-color: $input-bg;
   border-color: $input-border-color;
   &:hover {
      color: $body-color;
      background-color: $gray-100;
      border-color: $input-border-color;
   }
   &:focus {
      outline: auto;
   }
}
.dark-mode .input-group button.chooseFileButton {
   color: $form-text-dark-mode-color;
   background-color: $input-dark-mode-bg;
   border-color: $input-dark-mode-border-color;

   &:hover {
      color: $form-text-dark-mode-color;
      background-color: rgba($white, 0.05);
      border-color: $input-dark-mode-border-color;
   }
}

.input-group input.chooseFileInput {
   cursor: pointer;
   background-color: $input-bg;
}
.dark-mode .input-group input.chooseFileInput {
   background-color: $input-dark-mode-bg;
}

// -- Toggle tip

.toggle-tip {
   padding: 2px;
   opacity: 0.8;

   &:hover,
   &:focus {
      opacity: 1;
      color: #0e1927;
   }
}
.dark-mode .toggle-tip {
   &:hover,
   &:focus {
      color: #fff !important;
   }
}

// -- SchemaTree

.schema-tree-context-menu {
   min-width: 200px;
}

.schema-tree-expandable-node button:not(.schema-tree-extra-action) {
   font-weight: 500;
   height: 20px;

   .schema-tree-expandable-node-label {
      &:hover {
         color: #0e1927;
      }
   }
}
.dark-mode .schema-tree-expandable-node button:not(.schema-tree-extra-action) {
   .schema-tree-expandable-node-label {
      &:hover {
         color: white;
      }
   }
}

button.schema-tree-extra-action {
   &:hover {
      color: #0e1927 !important;
   }
}
.dark-mode button.schema-tree-extra-action {
   &:hover {
      color: white !important;
   }
}

.schema-highlighted {
   background-color: rgba(255, 222, 33, 0.5) !important;
   border-radius: 4px;
}
.dark-mode .schema-highlighted {
   background-color: rgba(144, 123, 0, 0.4) !important;
}

// -- Hide tabpane scrollbar
.tab-pane::-webkit-scrollbar,
.query-page-background::-webkit-scrollbar,
.hide-scrollbar::-webkit-scrollbar {
   display: none; /* Chrome, Safari, Opera*/
}

.tab-pane,
.query-page-background,
.hide-scrollbar {
   -ms-overflow-style: none; /* IE and Edge */
   scrollbar-width: none; /* Firefox */
}

// -- Util

.focus-outline {
   &:focus {
      outline: auto;
   }
}

.modal-header {
   padding-bottom: 0;
   border-bottom: 0;
}
.modal-footer {
   padding-top: 0;
   border-top: 0;
}

.saved-query-stats {
   background-color: #f4f5f6;
   color: #595959;
   padding: 5px 10px;
   justify-content: space-between;
   display: flex;
   align-items: center;
   border-radius: 4px;
   .dark-mode & {
      background-color: rgba(36, 50, 72, 0.3);
      color: #aaa;
   }
}

.align-items-center .form-check-input {
   margin-top: 0;
}

.explanation-textbox {
   border: none;
   border-radius: 0.25rem;
   min-height: 32px;
   padding: 8px 0 8px 0;

   h3 {
      font-size: 18px;
   }

   h4 {
      font-size: 14px;
   }

   &.readOnly {
      background-color: #f3f6ff;
   }
}

html.dark-mode .explanation-textbox {
   //border: 1px solid rgba(255, 255, 255, 0.18);
   border: none;

   &.readOnly {
      background-color: rgba(255, 255, 255, 0.01);
   }
}

.query-previewer {
   color: black;
}

html.dark-mode .query-previewer {
   color: white;
}

.answers-grid {
   width: 80%;
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: auto;
   row-gap: 0.5rem;
   column-gap: 0.5rem;
}

.chat-bubble-left {
   max-width: 80%;
}
.chat-bubble-right {
   max-width: 80%;
   align-self: flex-end;
}
.chat-bubble-wide {
   width: 80% !important;
}
.chat-bubble {
   white-space: pre-wrap;
   .query-card {
      background-color: transparent !important;
   }
}
.chat-bubble-internal {
   border-color: rgba(234, 179, 8, 0.8);
}
.dark-mode .chat-bubble-internal {
   border-color: rgba(234, 179, 8, 0.6);
}

.bg-chat-primary {
   background: #254589 !important;
   color: #fff;
}
.dark-mode .bg-chat-primary {
   background: #0f1f42 !important;
   color: #fff;
}

.chat-input {
   resize: none;
   padding: 0.5rem !important;
}

.form-switch-sm {
   font-size: 11px;
   line-height: 1;
   input {
      height: 11px !important;
      width: 22px !important;
   }
   label {
      font-size: 11px;
   }
}
.input-group .form-switch {
   line-height: 1.6;
   margin-bottom: 0;
   input {
      height: 11px !important;
      width: 22px !important;
   }
   label {
      font-size: 11px;
   }
}

.border-selected {
   border-color: #6a6ce1;
}

.empty-textarea {
   height: 1em !important;
}

.btn svg,
a svg {
   max-width: unset;
}

.bg-danger .form-control,
.bg-danger .typeselect-input-field {
   background: transparent;
   border-color: transparent !important;
   color: #fff;
}

.btn-link-primary {
   background-color: transparent;
   color: #4c82f7 !important;
   border-color: transparent;
}

.btn-link-primary:hover,
.btn-link-primary:active {
   color: #3a68cb !important;
}

.dark-mode {
   .btn-link-primary {
      background-color: transparent;
      color: #3a68cb !important;
      border-color: transparent;
   }

   .btn-link-primary:hover,
   .btn-link-primary:active {
      color: #4c82f7 !important;
   }
}

html {
   --state-new-color: rgba(234, 179, 8, 0.6);
   --state-open-color: rgba(249, 115, 22, 0.75);
   --state-pending-color: rgba(76, 130, 247, 0.9);
   --state-solved-color: rgba(34, 197, 94, 0.6);
   --state-accepted-color: rgba(31, 41, 55, 0.7);
   --state-cancelled-color: rgba(185, 28, 28, 0.7);

   .sidebar-card {
      border: 0;
      border-radius: 0;

      &.data-chat-state-new {
         border-left: 3px solid var(--state-new-color);
      }
      &.data-chat-state-open {
         border-left: 3px solid var(--state-open-color);
      }
      &.data-chat-state-pending {
         border-left: 3px solid var(--state-pending-color);
      }
      &.data-chat-state-solved {
         border-left: 3px solid var(--state-solved-color);
      }
      &.data-chat-state-accepted {
         border-left: 3px solid var(--state-accepted-color);
      }
      &.data-chat-state-cancelled {
         border-left: 3px solid var(--state-cancelled-color);
      }

      .sidebar-card-avatar {
         flex-shrink: 0;
         background-color: rgba(152, 160, 169, 1);
         color: #fff;
      }
   }

   .data-chat-state-badge {
      &.data-chat-state-new {
         color: #451a03;
         background-color: var(--state-new-color);
      }
      &.data-chat-state-open {
         color: #422006;
         background-color: var(--state-open-color);
      }
      &.data-chat-state-pending {
         color: #fff;
         background-color: var(--state-pending-color);
      }
      &.data-chat-state-solved {
         color: #052e16;
         background-color: var(--state-solved-color);
      }
      &.data-chat-state-accepted {
         color: #fff;
         background-color: var(--state-accepted-color);
      }
      &.data-chat-state-cancelled {
         color: #fff;
         background-color: var(--state-cancelled-color);
      }
   }

   .data-chat-state-label {
      &.data-chat-state-new {
         color: var(--state-new-color);
      }
      &.data-chat-state-open {
         color: var(--state-open-color);
      }
      &.data-chat-state-pending {
         color: var(--state-pending-color);
      }
      &.data-chat-state-solved {
         color: var(--state-solved-color);
      }
      &.data-chat-state-accepted {
         color: var(--state-accepted-color);
      }
      &.data-chat-state-cancelled {
         color: var(--state-cancelled-color);
      }
   }

   &.dark-mode {
      --state-new-color: rgba(234, 179, 8, 0.7);
      --state-accepted-color: rgba(107, 114, 128, 0.8);
      --state-cancelled-color: rgba(185, 28, 28, 0.6);

      .sidebar-card {
         .sidebar-card-avatar {
            background-color: rgba(34, 53, 87, 1);
         }
      }

      .data-chat-state-badge {
         color: #fff;
      }
   }
}
